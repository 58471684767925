import { useCallback, useEffect, useRef, useState } from 'react'
import { Icon } from '@iconify/react'
import {
  useDeleteTableState,
  useGetTableFilterState,
  usePostTableFilterState,
} from '../api/tableStateApi'
import _ from 'lodash'
import { ShowInfoPopover } from './InfoPopover'
import { OverlayTrigger, Popover } from 'react-bootstrap'

export interface FilterConditionModel {
  isFilterSave: boolean
  isColumnSave: boolean
}

interface CloudSettingProps {
  tableId: string
  isLoading: boolean
  filterCondition: FilterConditionModel
  setFilterCondition: (func: any) => void
}

const CloudSetting: React.FC<CloudSettingProps> = ({
  tableId,
  isLoading,
  filterCondition,
  setFilterCondition,
}) => {
  const [show, setShow] = useState<boolean>(false)
  // const [tableState, setTableState] = useState<any>(null)
  const postOnFilter = usePostTableFilterState()
  const deleteTableState = useDeleteTableState()
  const popoverRef = useRef(null)

  const { data: tableState, refetch, isLoading: isLoad } = useGetTableFilterState(tableId)

  const handleClearAllStates = (): void => {
    deleteTableState.mutate(tableId, {
      onSuccess: () => {
        window.location.reload()
      },
    })
  }

  // Debounced function
  const debouncedSearch = useCallback(
    _.debounce((isColumnSave: boolean, isFilterSave: boolean) => {
      postOnFilter.mutate({
        tableId: tableId,
        companyId: '',
        filterData: {
          isColumnSave,
          isFilterSave,
          filterState: tableState?.filterState,
          columnState: tableState?.columnState,
        },
      })
    }, 300),
    [filterCondition, tableState]
  )

  return (
    <div className='position-relative'>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement='top'
        rootCloseEvent='mousedown'
        overlay={HelpingPopover()}
      >
        <span
          style={{
            cursor: 'pointer',
          }}
        >
          <Icon
            icon='mdi:table-sync'
            className='text-gray-600 text-hover-primary me-2 mt-2'
            width='28'
            height='28'
            onClick={() => handleClearAllStates()}
          />
        </span>
      </OverlayTrigger>
    </div>
  )
}

const HelpingPopover = () => (
  <Popover id='popover-basic' className='min-w-300px'>
    <Popover.Body className='p-0'>
      <div className='text-justify'>
        <div
          className='p-3 d-flex align-items-center gap-2 rounded'
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
        >
          <Icon
            icon='fluent:table-settings-24-regular'
            className='text-white text-hover-primary'
            width='28'
            height='28'
          />
          <div>
            <h4 className='text-white fw-bold mb-0'>Reset Table State</h4>
            <p className='text-white small mb-0'>
              Click here to reset the table to the default view.
            </p>
          </div>
        </div>
      </div>
    </Popover.Body>
  </Popover>
)

export default CloudSetting
