import { useEffect, useState } from 'react'
import { formatNumber } from '../../../../../utils/CurrencyFormatter'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import Toast, { ToastType } from '../../../../../utils/toast'
import { downloadS3File } from '../../../../../utils/ExportExcel'
import { PDFModalViewer } from '../TabViews/PDFModalViewer'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import {
  CompanyTypes,
  ReGenerateCertificateQueryVariables,
  useGetCompanyWiseDataQuery,
  useReGenerateCertificateQuery,
} from '../../../../../generated/graphql'

export function getSecurityColor(securityType: string): string {
  switch (securityType?.toUpperCase()) {
    case 'EQUITY':
      return 'custom-security equity'
    case 'PREFERENCE':
      return 'custom-security preference'
    case 'CCD':
      return 'custom-security ccd'
    case 'OCD':
      return 'custom-security ocd'
    case 'NOTE':
      return 'custom-security note'
    case 'WARRANT':
      return 'custom-security warrant'
    case 'RPS':
      return 'custom-security rps'
    case 'NCD':
      return 'custom-security ncd'
    case 'OCPS':
      return 'custom-security ocps'
    case 'OCRPS':
      return 'custom-security ocrps'
    case 'DEBENTURE':
      return 'custom-security debenture'
    case 'OPTIONS':
      return 'custom-security options'
    case 'CCPS':
      return 'custom-security ccps'
    case 'FORFEITED':
      return 'custom-security forfeited'
    default:
      return 'bg-light text-success'
  }
}

export function SecurityClassRender(data: any) {
  return (
    <div className='pt-2'>
      <p className='fw-bold mb-0'>{data.data.securityClass}</p>
      {data.data.demat && (
        <p className='px-2 border mb-2 fs-8 border-success bg-light-success text-success  d-inline-block'>
          Demat
        </p>
      )}
    </div>
  )
}

export function SecurityTypeRender(data: any) {
  return (
    <div
      className={`${getSecurityColor(
        data.data.securityType
      )} mt-2 fs-7 py-1 text-center align-middle fw-semibold`}
    >
      <p className='mb-0'>{data.data.securityType}</p>
    </div>
  )
}

export function RoundsRender(data: any) {
  return (
    <div className='text-start'>
      {Object.keys(data.data.rounds).length > 0 && (
        <div
          className='pt-2'
          data-bs-toggle='tooltip'
          title={data.data.rounds.roundIdentifier + ' - ' + data.data.rounds.roundName}
        >
          <p className='border mb-0 border-gray-600 text-gray-600 rounded-1 d-inline-block px-2'>
            {data.data.rounds.roundIdentifier}
          </p>
        </div>
      )}
      {Object.keys(data.data.rounds).length === 0 && <div className='pt-2 mx-2'>-</div>}
    </div>
  )
}

export function ShareHolderRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.shareHolder}</p>
    </div>
  )
}

export function ShareNumbersRender(data: any) {
  const [viewMore, setViewMore] = useState<boolean>(false)

  return (
    <div className='pt-2'>
      {data.data?.shareNumbers?.map((share: any, index: any) =>
        viewMore ? (
          <p key={index} className='mb-1 fw-semibold'>
            {share}
          </p>
        ) : (
          index < 3 && (
            <p key={index} className='mb-1 fw-semibold'>
              {share}
            </p>
          )
        )
      )}

      {data.data.shareNumbers?.length > 3 && (
        <p
          className={`cursor-pointer mb-1 fw-semibold`}
          onClick={() => {
            setViewMore(!viewMore)
          }}
        >
          {viewMore ? 'View Less' : 'View More'}
        </p>
      )}
    </div>
  )
}

export function NumOfSecuritiesRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.numOfSecurities,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function PPSRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.pps,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function AmountInvestedRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.amountInvested,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function CurrentValueRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.currentValue,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}

export function transformRangesArraytoString(ranges: number[][], securityType?: string) {
  let type = securityType ? getTypeOfSecurity(securityType)[0] : ''
  type = type === 'E' ? '' : type
  return ranges?.map((range) => type + range.toString().replace(',', `-${type}`))?.toString() || ''
}

export enum Security {
  Equity = 'Equity',
  CCPS = 'CCPS',
  OCPS = 'OCPS',
  RPS = 'RPS',
  CCD = 'CCD',
  OCD = 'OCD',
  NCD = 'NCD',
  Note = 'Note',
  Warrant = 'Warrant',
  OCRPS = 'OCRPS',
  Options = 'Options',
  Forfeited = 'Forfeited',
}

export function getTypeOfSecurity(securityType: string): string {
  if (securityType === Security.Equity) {
    return 'Equity'
  } else if (
    securityType === Security.CCPS ||
    securityType === Security.OCPS ||
    securityType === Security.OCRPS ||
    securityType === Security.RPS
  ) {
    return 'Preference'
  } else if (
    securityType === Security.CCD ||
    securityType === Security.NCD ||
    securityType === Security.OCD
  ) {
    return 'Debenture'
  } else if (securityType === Security.Options) {
    return 'Options'
  } else if (securityType === Security.Warrant) {
    return 'Warrant'
  } else if (securityType === Security.Note) {
    return 'Note'
  } else if (securityType === Security.Forfeited) {
    return 'Forfeited'
  } else {
    return 'Equity'
  }
}

export function CertificateActionRender(data: any) {
  const [showCertificateModal, setShowCertificateModal] = useState(false)
  const [reGenerate, setReGenerate] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [data.data.transactionDetails])

  const { isSuccess, isError, isLoading } = useReGenerateCertificateQuery<
    ReGenerateCertificateQueryVariables,
    Error
  >(
    graphqlRequestClient,
    {
      input: {
        certificateId: data.data.certificateId,
        hissaCompanyId: data.data.companyDetails.hissaCompanyId,
        type: CompanyTypes.Hissa,
      },
    },
    {
      enabled: reGenerate,
      retry: false,
    }
  )

  useEffect(() => {
    if (isSuccess && reGenerate) {
      const compKey = {
        input: {
          type: data.data.companyDetails?.type,
          hissaCompanyId: data.data.companyDetails?.hissaCompanyId || '',
          id: Number(data.data.companyDetails?.id) || 0,
        },
      }
      setReGenerate(false)
      const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
      queryClient.invalidateQueries(getCompanyWiseQuery)
      Toast('Re-Generated Certificate Successfully', ToastType.success)
    }
    if (isError && reGenerate) {
      setReGenerate(false)
      Toast('Error Re-Generating Certificate', ToastType.error)
    }
  }, [isSuccess, isError])

  const options = [
    {
      name: 'View Share Certificate',
      isDisabled: data.data.certificateUrl === '',
    },
    { name: 'Re-Generate Share Certificate', isDisabled: false },
    {
      name: 'Download Share Certificate',
      isDisabled: data.data.certificateUrl === '',
    },
  ]
  const actionMenu = () => {
    return options.map((option) => {
      return (
        <label
          key={option.name}
          className={`form-check-custom py-3 ${
            option.isDisabled
              ? ' disabled text-gray-400'
              : 'bg-hover-primary text-hover-white cursor-pointer'
          }`}
          onClick={() => {
            if (option.name === 'Download Share Certificate') {
              downloadS3File(data.data.certificateUrl)
            } else if (
              option.name === 'View Share Certificate' &&
              data.data.certificateUrl &&
              data.data.certificateUrl !== ''
            ) {
              setShowCertificateModal(true)
            } else if (option.name === 'Re-Generate Share Certificate') {
              setReGenerate(true)
            }
          }}
        >
          <span className='form-check-label fs-base ms-6 fw-normal '>{option.name}</span>
        </label>
      )
    })
  }
  return (
    <div className='px-0'>
      {data.data.investorName !== 'Total' && data.data.dateOfInvestment !== null && (
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen052.svg'
            className='svg-icon-muted svg-icon-2x'
          />
        </button>
      )}

      {showCertificateModal && (
        <PDFModalViewer
          show={showCertificateModal}
          handleClose={() => setShowCertificateModal(false)}
          url={data.data.certificateUrl || ''}
          documentName={data.data.certificateName || ''}
        />
      )}

      <div
        className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-225px py-3 ${
          isLoading ? 'disabled' : ''
        }`}
        data-kt-menu='true'
      >
        {actionMenu()}
      </div>
    </div>
  )
}
