import { AgGridReact } from 'ag-grid-react'
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { agConfigSideBar, tableCellStyle } from '../../../../../utils/AgGridConfig'
import { ColDef } from 'ag-grid-community'
import {
  CompanyObject,
  GetDealTemplatesQuery,
  InvestorRoundModellingData,
  useGetDealTemplatesQuery,
} from '../../../../../generated/graphql'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { GlobalContext } from '../../../store/context/globalContext'
import { getCurrency } from '../../../../../utils/CurrencyFormatter'
import { InvestmentRoundDetails } from './InvestmentModellingHelper'
import {
  DilutionRender,
  InvestmentModelActionRender,
  ModelNameRender,
  PostMoneyRender,
  RoundNameRender,
  RoundSizeRender,
} from './InvestmentModellingAGComponents'
import { graphqlRequestClient } from '../../../../../queries/client'
import { useTableStateManagement } from '../../../../../utils/TableHook'
import { TableId } from '../../../../../utils/TableIdConstants'
import CloudSetting from '../../../../../utils/tableStateIcon'
import { useCompanyNameStore } from '../../../../../store/companyNameStore'

type TransactionProps = {
  investmentData?: InvestorRoundModellingData[]
  hissaCompanyId: string
  companyData: CompanyObject
}

export function InvestmentModellingAGTable(props: TransactionProps) {
  const gridApi = useRef<any>(null)
  let investmentModelData: InvestorRoundModellingData[] =
    props.investmentData as InvestorRoundModellingData[]

  const companyNameStore = useCompanyNameStore()
  const companyCurrency = companyNameStore.companyCurrency
  const companyCurrencyFormat = getCurrency(companyCurrency)

  const [pageLoaded, setPageLoaded] = useState(false)
  const companyId = props.companyData?.hissaCompanyId || props.companyData?.id
  const { filterCondition, setFilterCondition, isSaving, uploadFilter, uploadColumn, tableState } =
    useTableStateManagement(
      gridApi,
      TableId.investmentModellingTable + companyId + 'web',
      pageLoaded
    )

  const { data } = useGetDealTemplatesQuery<GetDealTemplatesQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const componentsRegistry = useMemo(
    () => ({
      modelName: memo(ModelNameRender),
      roundName: memo(RoundNameRender),
      roundSize: memo(RoundSizeRender),
      postMoney: memo(PostMoneyRender),
      dilution: memo(DilutionRender),
    }),
    []
  )

  const showNoRowsOverlay = () => (
    <div className='row justify-content-center  text-center py-8 m-6'>
      <img
        alt='no items'
        src={toAbsoluteUrl('/media/icons/duotune/general/empty-watchlist.svg')}
        className='h-150px my-10 w-350 justify-content-center'
      />
      <p className='fs-base text-muted text-center'>
        No Investment Model found, Get started by adding New Model.
      </p>
    </div>
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const [filteredRowData, setFilteredRowData] = useState<any>([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent()
    setIsFilterApplied(filtersApplied)

    const filteredData = grid.api.getModel().rowsToDisplay.map((node: any) => node.data)
    setFilteredRowData(filteredData)

    uploadFilter()
  }

  useEffect(() => {
    const filterModel = gridApi.current?.api.getFilterModel()

    const filteredData = gridApi.current?.api.getModel().rowsToDisplay.map((node: any) => node.data)

    setFilteredRowData(filteredData)
    gridApi.current?.api.setFilterModel(tableState?.filterState)
  }, [gridApi, tableState?.filterState, tableState?.columnState, investmentModelData])

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Model Name',
        field: 'modelName',
        cellRenderer: 'modelName',
        cellStyle: tableCellStyle,
        pinned: 'left',
        initialWidth: 250,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Round Name',
        field: 'roundName',
        filter: 'agTextColumnFilter',
        cellRenderer: 'roundName',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Round Size',
        field: 'roundSize',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'roundSize',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipRoundSize',
      },
      {
        headerName: 'Post Money',
        field: 'postMoney',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'postMoney',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipPostMoney',
      },
      {
        headerName: 'Dilution',
        field: 'dilution',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'dilution',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipDilution',
      },

      {
        headerName: '',
        field: 'actions',
        pinned: 'right',
        hide: false,
        width: 60,
        maxWidth: 60,
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: {
          'padding-left': '9px',
          'padding-right': '4px',
          'padding-top': '3px',
          'line-height': '20px',
        },
        resizable: false,
        sortable: false,
        cellRenderer: InvestmentModelActionRender,
      },
    ],
    [companyCurrencyFormat]
  )

  const rowData = useMemo(() => {
    setPageLoaded(true)
    return investmentModelData?.map((modelData: InvestorRoundModellingData) => {
      const roundData = modelData.investmentModellingData.roundData as InvestmentRoundDetails
      const dilution = (roundData.roundSize / roundData.postMoneyValuation) * 100
      const investedAmount = modelData.investmentModellingData.investmentData.reduce(
        (acc: any, curr: any) => acc + curr.investmentAmount,
        0
      )
      return {
        modelId: modelData.id,
        modelName: modelData.name,
        roundName: roundData.roundName,
        roundSize: Math.abs(roundData.roundSize),
        tooltipRoundSize: Math.abs(roundData.roundSize).toLocaleString(companyCurrencyFormat),
        postMoney: Math.abs(roundData.postMoneyValuation),
        tooltipPostMoney: Math.abs(roundData.postMoneyValuation).toLocaleString(
          companyCurrencyFormat
        ),
        dilution: Math.abs(dilution),
        tooltipDilution: Math.abs(dilution).toLocaleString(companyCurrencyFormat),
        modelData: modelData,
        investedAmount: investedAmount,
        dealTemplate: data?.dealTemplates,
        hissaCompanyId: props.hissaCompanyId,
        companyData: props.companyData,
        companyCurrencyFormat,
      }
    })
  }, [investmentModelData, companyCurrencyFormat, data])

  return (
    <div
      className='card p-0 h-500px overflow-auto'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='d-flex flex-row border-0 p-9'>
        <div className='card-title align-items-end flex-column'>
          <h1 className='m-0'>Investment Modelling</h1>
          <span className='text-muted'></span>
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          <CloudSetting
            tableId={TableId.investmentModellingTable + companyId + 'web'}
            isLoading={isSaving}
            filterCondition={filterCondition}
            setFilterCondition={setFilterCondition}
          />
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          onFilterChanged={onAgGridFilterChanged}
          onColumnMoved={uploadColumn}
          onColumnEverythingChanged={uploadColumn}
          onColumnResized={uploadColumn}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={true}
          enableRangeSelection={false}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          noRowsOverlayComponent={showNoRowsOverlay}
          suppressMenuHide={false}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
