import axios from 'axios'
import { QueryFunctionContext, useMutation, useQuery } from 'react-query'
import { capTableTableStateApi } from '../queries/capTableApi'

export interface TableStateModel {
  filterState: any
  columnState: any
  isFilterSave: boolean
  isColumnSave: boolean
}

export async function deleteTableState(tableId: string): Promise<any> {
  return capTableTableStateApi
    .delete(`/v1/utils/tableState?id=${tableId}&platform=INVESTOR`)
    .then((res) => res.data)
}

export async function postTableFilterState({
  tableId,
  companyId,
  filterData,
}: {
  tableId: string
  companyId: string
  filterData: TableStateModel
}): Promise<any> {
  const postData = {
    id: tableId,
    data: filterData,
    companyId,
    platform: 'INVESTOR',
  }
  return capTableTableStateApi.put(`/v1/utils/tableState`, postData).then((res) => res.data)
}

export async function getTableFilterState(tableId: string): Promise<any> {
  return capTableTableStateApi
    .get(`/v1/utils/tableState?id=${tableId}&platform=INVESTOR`)
    .then((res) => res.data.data)
}

export function useGetTableFilterState(tableId: string) {
  return useQuery({
    queryKey: ['getTableFilterState', tableId],
    queryFn: () => getTableFilterState(tableId), // Ensure it receives tableId
    staleTime: 0,
    cacheTime: 0,
  })
}

export function usePostTableFilterState() {
  return useMutation({
    mutationFn: postTableFilterState,
    mutationKey: 'postTableFilterState',
  })
}

export function useDeleteTableState() {
  return useMutation({
    mutationKey: 'deleteTableState',
    mutationFn: deleteTableState,
  })
}
