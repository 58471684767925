export function downloadBlobObject(blob: Blob, fileName: string) {
  const anchor = document.createElement('a')
  const blobURL = URL.createObjectURL(blob)
  anchor.href = blobURL
  anchor.download = fileName
  anchor.click()
}

export async function convertBase64ToBlob(base64: string, type: string) {
  return fetch(`data:${type};base64,${base64}`).then((res) => res.blob())
}

export function downloadS3File(file: string) {
  const anchor = document.createElement('a')
  anchor.href = file
  anchor.target = '_blank'
  anchor.download = ''
  anchor.click()
}

export const portCompHeadersObj: any = {
  companyName: 'Company Name',
  investmentVehicle: '',
  amount: 'Total',
  shares: 'Total Shares',
  date: '',
  roundValn: '',
  roundSize: '',
  roundPreValn: '',
  sharePrice: '',
  coInvestors: '',
  dilution: '',
  moic: '',
  irr: '',
  holding: '',
  rounds: '',
}
export const portCompHeadersList: string[] = [
  'companyName',
  'investmentVehicle',
  'amount',
  'shares',
  'date',
  'roundValn',
  'roundSize',
  'roundPreValn',
  'sharePrice',
  'coInvestors',
  'dilution',
  'moic',
  'irr',
  'holding',
  'rounds',
]
export const portCompTopHeadersList: string[] = [
  'Investment',
  '',
  'Amount',
  'Shares',
  'Date',
  'Round Post Money Valuation',
  'Round Size',
  'Round Pre Money Valuation',
  'Share Price',
  'Co-Investors',
  'Dilution',
  'MOIC',
  'IRR',
  'Holding %',
  'Rounds',
]
export type InvDetails = {
  companyName: string
  investmentVehicle: string
  amount: number
  shares: number
  date: string
  roundValn: number
  roundSize: number
  roundPreValn: number
  sharePrice: number
  coInvestors: string
  dilution: number
  moic: string
  irr: string
  holding: string
  rounds: string
}

export type CompWiseDetails = {
  companyName: string
  sector: string
  valuation: number
  valuationDate: string
  totalFunding: number
  sharePrice: number
  lastRoundName: string
  lastRaise: number
  lastRaiseDate: string
  yourHolding: string
  investedAmount: string
  founderHolding: string
  esopHolding: string
  coInvestors: string
  foundedYear: number
  incorporationDate: string
}
export const compWiseHeaders: string[] = [
  'companyName',
  'sector',
  'valuation',
  'valuationDate',
  'totalFunding',
  'sharePrice',
  'lastRoundName',
  'lastRaise',
  'lastRaiseDate',
  'yourHolding',
  'investedAmount',
  'founderHolding',
  'esopHolding',
  'coInvestors',
  'foundedYear',
  'incorporationDate',
]
export const compWiseHeadersObj: any = {
  companyName: 'Company Name',
  sector: 'Sector',
  valuation: 'Valuation',
  valuationDate: 'Valuation Date',
  totalFunding: 'Total Funding',
  sharePrice: 'Current Share Price',
  lastRoundName: 'Last Round Name',
  lastRaise: 'Last Raise',
  lastRaiseDate: 'Last Raise Date',
  yourHolding: 'Your Holding',
  investedAmount: 'Invested Amount',
  founderHolding: 'Founder Holding',
  esopHolding: 'ESOP Holding',
  coInvestors: 'Co-Investors',
  foundedYear: 'Founded Year',
  incorporationDate: 'Incorporation Date',
}
export const overviewKey: string[] = [
  'noPortComp',
  'noInvestments',
  'totalInvested',
  'totalFirstCheques',
  'avgInvestment',
  'noFollowRounds',
  'noFollowCapital',
  'coInvestors',
]
export const overviewLabel: any = {
  noPortComp: 'Number of Portfolio Companies',
  noInvestments: 'Number of Investments',
  totalInvested: 'Total Invested Amount',
  totalFirstCheques: 'Total First Cheques',
  avgInvestment: 'Average Investment',
  noFollowRounds: 'Number of Follow on Rounds',
  noFollowCapital: 'Amount of Follow on Capital',
  coInvestors: 'Co-Investors',
}
