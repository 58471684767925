import { AgGridReact } from 'ag-grid-react'
import { memo, useContext, useMemo, useRef, useState } from 'react'
import { agConfigSideBar, tableCellStyle } from '../../../../../utils/AgGridConfig'
import { ColDef } from 'ag-grid-community'
import {
  AllTransactionsType,
  CompanyObject,
  TransactionObject,
  Transaction_Type,
} from '../../../../../generated/graphql'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { GlobalContext } from '../../../store/context/globalContext'
import { getCurrency, getSymbolOfCurrency } from '../../../../../utils/CurrencyFormatter'
import { AddTransactionRoundDetails, InvestmentRoundDetails } from './InvestmentModellingHelper'
import { AddRoundTransactionModal } from '../../../manual-investments/components/AddRoundTransaction/AddRoundTransactionModal'
import {
  AmountInvestedRender,
  InvestmentActionRender,
  InvestorNameRender,
  NominalEquitySharesRender,
  NoOfSharesRender,
  PercentageRender,
  SecuritiesRender,
  SharePriceRender,
} from './TransactionAGComponents'
import { SelectOptionType } from '../../../../../utils/MultiSelect'
import { useCompanyNameStore } from '../../../../../store/companyNameStore'

type TransactionProps = {
  data?: AddTransactionRoundDetails[]
  companyData: CompanyObject
  roundDetails: InvestmentRoundDetails
  setInvestmentData: Function
  removeInvestmentData: Function
  currentRoundSize: number
  shareHolderNameList: SelectOptionType[]
  sharePrice: number
  currentPercentage: number
}

export function TransactionAGTable(props: TransactionProps) {
  const gridApi = useRef<any>(null)
  let transactionData: AddTransactionRoundDetails[] =
    (props.data as AddTransactionRoundDetails[]) || []
  let companyDetails: CompanyObject = props.companyData as CompanyObject
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  const companyNameStore = useCompanyNameStore()
  const companyCurrency = companyNameStore.companyCurrency
  const companyCurrencyFormat = getCurrency(companyCurrency)

  const [showModal, setShowModal] = useState<boolean>(false)

  const componentsRegistry = useMemo(
    () => ({
      investorName: memo(InvestorNameRender),
      security: memo(SecuritiesRender),
      sharePrice: memo(SharePriceRender),
      numOfShares: memo(NoOfSharesRender),
      amountInvested: memo(AmountInvestedRender),
      percentage: memo(PercentageRender),
      nominalEquityShares: memo(NominalEquitySharesRender),
    }),
    []
  )

  const showNoRowsOverlay = () => (
    <div className='row justify-content-center  text-center py-8 m-6'>
      <img
        alt='no items'
        src={toAbsoluteUrl('/media/icons/duotune/general/empty-watchlist.svg')}
        className='h-150px my-10 w-350 justify-content-center'
      />
      <p className='fs-base text-muted text-center'>
        No Investment Found, Get started by adding new investment.
      </p>
    </div>
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  // const [dataToggle, setDataToggle] = useState('investments')
  // const [showModal, setShowModal] = useState<boolean>(false)

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Investor Name',
        field: 'investorName',
        cellRenderer: 'investorName',
        cellStyle: tableCellStyle,
        pinned: 'left',
        initialWidth: 250,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Security',
        field: 'security',
        filter: 'agTextColumnFilter',
        cellRenderer: 'security',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'No. of Securities',
        field: 'numOfShares',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'numOfShares',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipNumOfShares',
      },
      // {
      //   headerName: `Share Price (${getSymbolOfCurrency(userCurrency)})`,
      //   field: 'sharePrice',
      //   filter: 'agNumberColumnFilter',
      //   cellRenderer: 'sharePrice',
      //   cellStyle: tableCellStyle,
      //   sortable: true,
      //   menuTabs: ['filterMenuTab'],
      //   tooltipField: 'tooltipSharePrice',
      // },
      {
        headerName: `Amount Invested (${getSymbolOfCurrency(companyCurrency)})`,
        field: 'amountInvested',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'amountInvested',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipAmountInvested',
      },
      {
        headerName: `Investment Percentage (%)`,
        field: 'percentage',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'percentage',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipPercentage',
      },
      {
        headerName: `Nominal Equity Shares`,
        field: 'nominalEquityShares',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'nominalEquityShares',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipNominalEquityShares',
      },
      {
        headerName: '',
        field: 'actions',
        pinned: 'right',
        hide: false,
        width: 60,
        maxWidth: 60,
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: {
          'padding-left': '9px',
          'padding-right': '4px',
          'padding-top': '3px',
          'line-height': '20px',
        },
        resizable: false,
        sortable: false,
        cellRenderer: InvestmentActionRender,
      },
    ],
    [companyCurrencyFormat]
  )

  const rowData = useMemo(
    () =>
      transactionData?.map((transaction: AddTransactionRoundDetails) => {
        return {
          investorName: transaction.shareholderName,
          security: transaction.securityType,
          numOfShares: Math.abs(transaction.noOfShares),
          sharePrice: Math.abs(transaction.pricePerShare),
          nominalEquityShares: Math.abs(transaction.nominalEquityShares),
          amountInvested: Math.abs(transaction.investmentAmount),
          percentage: Math.abs(transaction.percentage),
          tooltipPercentage: Math.abs(transaction.percentage).toLocaleString(companyCurrencyFormat),
          tooltipAmountInvested: Math.abs(transaction.investmentAmount).toLocaleString(
            companyCurrencyFormat
          ),
          tooltipNumOfShares: Math.abs(transaction.noOfShares).toLocaleString(
            companyCurrencyFormat
          ),
          tooltipNominalEquityShares: Math.abs(transaction.nominalEquityShares).toLocaleString(
            companyCurrencyFormat
          ),
          tooltipSharePrice: Math.abs(transaction.pricePerShare).toLocaleString(
            companyCurrencyFormat
          ),
          companyCurrencyFormat: companyCurrencyFormat,
          companyCurrency: companyCurrency,
          transactionDetails: transaction,
          companyDetails: companyDetails,
          roundDetails: props.roundDetails,
          setInvestmentData: props.setInvestmentData,
          removeInvestmentData: props.removeInvestmentData,
          currentRoundSize: props.currentRoundSize,
          shareHolderNameList: props.shareHolderNameList,
          currentSharePrice: props.sharePrice,
          transactionData: transactionData,
          currentPercentage: props.currentPercentage,
        }
      }),
    [transactionData, companyCurrencyFormat]
  )

  return (
    <div className='card h-500px mt-md-0 overflow-auto'>
      <div className='d-flex flex-row border-0 p-9'>
        <div className='card-title align-items-end flex-column'>
          <h1 className='m-0'>Investments</h1>
          {userCurrency !== companyCurrency && (
            <p className='fs-8 text-gray-600 d-flex justify-content-end'>
              <strong>Note:</strong>{' '}
              {`Modelling currency will be same as company Currency (${getSymbolOfCurrency(
                companyCurrency
              )}).`}
            </p>
          )}
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          <button
            type='button'
            className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_1'
            onClick={() => {
              setShowModal(!showModal)
            }}
          >
            Add Investment
          </button>
          {showModal && (
            <AddRoundTransactionModal
              handleClose={() => {
                setShowModal(false)
              }}
              showModal={showModal}
              roundDetails={props.roundDetails}
              setInvestmentData={props.setInvestmentData}
              currentRoundSize={props.currentRoundSize}
              shareHolderNameList={props.shareHolderNameList}
              sharePrice={props.sharePrice}
              isEdit={false}
              removeInvestmentData={props.removeInvestmentData}
              currentPercentage={props.currentPercentage}
              investments={props.data!}
            />
          )}
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={true}
          enableRangeSelection={false}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          noRowsOverlayComponent={showNoRowsOverlay}
          suppressMenuHide={false}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
