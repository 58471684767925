import { create } from 'zustand'
import { configurePersist } from 'zustand-persist'
import { FilterTagValue } from '../app/pages/portfolio/components/FilterMenu'

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: 'root',
})

const persist = _persist as any

export type CompanyNameState = {
  companyName: string
  setCompanyName: (value: string) => Function
  companyCurrency: string
  setCompanyCurrency: (value: string) => Function
  reset: () => Function
}

const initialState = {
  companyName: '' as string,
  companyCurrency: '' as string,
}

const companyNameStore = create<CompanyNameState>(
  persist(
    {
      key: 'companyName',
    },
    (set: any) => ({
      ...initialState,
      setCompanyName: (name: string) => {
        set((state: any) => ({ companyName: name }))
      },
      setCompanyCurrency: (currency: string) => {
        set((state: any) => ({ companyCurrency: currency }))
      },
      reset: () => {
        set(() => initialState)
      },
    })
  )
)

export const useCompanyNameStore = companyNameStore
