/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { AllTransactionsType } from '../../../../generated/graphql'
import { usePortfolioStore } from '../../../../store/portfolioFilterDataStore'

type Props = {
  allTransactions: AllTransactionsType[]
  handleFilterSelection: Function
  isCompanyPage?: boolean
}

type FilterType = {
  label: string
  isSelected: boolean
  isFiltered: boolean
  type: FilterLabel
}

export type FilterTagValue = {
  group: FilterLabel
  label: string
  selected: boolean
}

export enum FilterLabel {
  industry,
  fundVehicle,
  tag,
}

interface FilterCategoryMapping {
  [key: string]: FilterLabel
}

function FilterMenuView({ allTransactions, handleFilterSelection, isCompanyPage }: Props) {
  const useFilterStore = usePortfolioStore()
  const [selectedFilterTagValues, setSelectedFilterTagValues] = useState<FilterTagValue[]>(
    isCompanyPage ? [] : useFilterStore.selectedFilter
  )

  const [selectedFilterCategory, setSelectedFilterCategory] = useState<FilterLabel>(
    FilterLabel.fundVehicle
  )
  const filtersList = isCompanyPage ? ['Fund Vehicle'] : ['Fund Vehicle', 'Industry', 'Tag']

  const { customTagsList, fundsList, industriesList } = generateDataForTags(allTransactions)
  const [filterTagValues, setFilterTagValues] = useState<FilterTagValue[]>(fundsList)

  useEffect(() => {
    if (selectedFilterCategory === FilterLabel.fundVehicle) {
      setFilterTagValues(fundsList)
    } else if (selectedFilterCategory === FilterLabel.industry) {
      setFilterTagValues(industriesList)
    } else if (selectedFilterCategory === FilterLabel.tag) {
      setFilterTagValues(customTagsList)
    }
  }, [selectedFilterCategory, allTransactions])

  useEffect(() => {
    handleFilterSelection(selectedFilterTagValues)
    if (selectedFilterTagValues && !isCompanyPage)
      useFilterStore.setFilteredValue(selectedFilterTagValues)
  }, [selectedFilterTagValues])

  const handleFilterCategorySelection = (category: FilterLabel, isClearFilter: boolean) => {
    if (isClearFilter) {
      const clearFilterTagValues = selectedFilterTagValues.filter((t) => t.group !== category)
      setSelectedFilterTagValues(clearFilterTagValues)
    }
    setSelectedFilterCategory(category)
  }

  const handleTagValueSelection = (tagValue: FilterTagValue, isSelect: boolean) => {
    if (isSelect) {
      const isTagValueSelected = selectedFilterTagValues.find(
        (obj) => obj.group === tagValue.group && obj.label === tagValue.label
      )
      if (!isTagValueSelected) {
        tagValue.selected = true
        setSelectedFilterTagValues([...selectedFilterTagValues, tagValue])
      }
    } else {
      if (
        selectedFilterTagValues.find(
          (obj) => obj.group === tagValue.group && obj.label === tagValue.label
        )
      ) {
        const filteredTagValues = selectedFilterTagValues.filter(
          (t) => !(t.group === tagValue.group && t.label === tagValue.label)
        )
        setSelectedFilterTagValues(filteredTagValues)
      }
    }
  }

  const filterCategoryMapping: FilterCategoryMapping = {
    'Fund Vehicle': FilterLabel.fundVehicle,
    Industry: FilterLabel.industry,
    Tag: FilterLabel.tag,
  }

  const filterCategories = createFilters(
    filtersList.map((filter) => ({
      label: filter,
      isSelected: selectedFilterCategory === (filterCategoryMapping[filter] as FilterLabel),
      isFiltered:
        selectedFilterTagValues.filter(
          (tagValue) => tagValue.group === (filterCategoryMapping[filter] as FilterLabel)
        ).length > 0,
      type: filterCategoryMapping[filter] as FilterLabel,
    })),
    handleFilterCategorySelection
  )

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 border border-growth-card menu-state-bg-light-primary fw-bold mw-600px h-200px py-3 me-3'
      data-kt-menu='true'
    >
      <div className='d-flex flex-row'>
        <div className='col-5 col-md-5 col-sm-6 border-gray-400 border-end border-2'>
          <div
            className='card mh-150px my-4'
            style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'none' }}
          >
            {filterCategories}
          </div>
        </div>

        <div className='col-7 col-md-7 col-sm-6'>
          <div
            className='card mh-150px mx-2 my-5'
            style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'none' }}
          >
            {filterTagValues &&
              createFilterTags(filterTagValues, selectedFilterTagValues, handleTagValueSelection)}
          </div>
        </div>
      </div>
    </div>
  )
}

function createFilters(filters: FilterType[], handleFilterCategorySelection: Function) {
  return filters.map((filter, index) => {
    return (
      <div className='row mx-4 my-1 justify-content-center' key={index}>
        <div
          className={`${
            filter.isSelected
              ? 'border border-primary text-gray-800 '
              : filter.isFiltered
              ? 'border border-primary text-primary'
              : 'border border-transparent border-hover-primary-light'
          } pe-0 ps-2 py-2 rounded w-190px row`}
        >
          <span
            className='col-10 px-1'
            onClick={() => handleFilterCategorySelection(filter.type, false)}
            style={{ cursor: 'pointer' }}
          >
            {' '}
            Filter by {filter.label}
          </span>
          {filter.isFiltered && (
            <span
              className='col-2 text-end ps-2'
              onClick={() => handleFilterCategorySelection(filter.type, true)}
              style={{ cursor: 'pointer' }}
            >
              <KTSVG
                path='/media/icons/duotune/abstract/abs012.svg'
                className='svg-icon-1 svg-icon-gray-500 text-primary'
              />
            </span>
          )}
        </div>
      </div>
    )
  })
}

function createFilterTags(
  filterTagValues: FilterTagValue[],
  selectedFilterTagValues: FilterTagValue[],
  handleTagValueSelection: Function
) {
  filterTagValues.map((tag) => {
    const isSelected = selectedFilterTagValues.find(
      (t) => t.group === tag.group && t.label === tag.label
    )
    if (isSelected) {
      tag.selected = true
    } else {
      tag.selected = false
    }
    return tag
  })
  return (
    <div className='mx-5 mt-0'>
      {filterTagValues
        .sort((a, b) => (a.selected === b.selected ? 0 : a.selected ? -1 : 1))
        .map((filterTagValue, index) => {
          return (
            <span
              className={`${
                filterTagValue.selected
                  ? 'border border-primary text-primary'
                  : 'border border-gray-800'
              } m-2 p-2 rounded border-2`}
              onClick={() => handleTagValueSelection(filterTagValue, !filterTagValue.selected)}
              style={{ cursor: 'pointer' }}
              key={index}
            >
              <div className='badge text-wrap text-gray-800 fs-7 m-3 mx-0 px-1 justify-content-between'>
                {filterTagValue.label}
                {filterTagValue.selected && (
                  <span className='d-flex justify-content-end ps-2' style={{ cursor: 'pointer' }}>
                    <KTSVG
                      path='/media/icons/duotune/abstract/abs012.svg'
                      className='svg-icon-1 svg-icon-gray-500 text-primary '
                    />
                  </span>
                )}
              </div>
            </span>
          )
        })}
    </div>
  )
}

function generateDataForTags(allTransactions: AllTransactionsType[]) {
  const fundsList: FilterTagValue[] = []
  const industriesList: FilterTagValue[] = []
  const customTagsList: FilterTagValue[] = []
  const investorObjects: string[] = []
  const sectorsObjects: string[] = []
  const customTagsObjects: string[] = ['All', 'Hissa', 'Manual', 'Indirect', 'Exited']
  allTransactions.forEach((company) => {
    const tags = (company.companyData.tags as string[]) || []
    if (tags.length > 0) {
      tags.forEach((tag) => {
        if (!customTagsObjects.includes(tag)) {
          customTagsObjects.push(tag)
        }
      })
    }
    company.investorData?.forEach((inv) => {
      if (!investorObjects.includes(inv?.name!)) {
        investorObjects.push(inv?.name!)
      }
    })
    if (company.companyData.industry) {
      if (!sectorsObjects.includes(company.companyData.industry.toUpperCase())) {
        sectorsObjects.push(company.companyData.industry.toUpperCase())
      }
    }
    if (!company.companyData.industry) {
      if (!sectorsObjects.includes('OTHERS')) {
        sectorsObjects.push('OTHERS')
      }
    }
  })

  investorObjects.forEach((inv) => {
    fundsList.push({
      group: FilterLabel.fundVehicle,
      label: inv,
      selected: true,
    })
  })
  sectorsObjects.forEach((industry) => {
    industriesList.push({
      group: FilterLabel.industry,
      label: industry,
      selected: true,
    })
  })

  customTagsObjects.forEach((tag) => {
    customTagsList.push({
      group: FilterLabel.tag,
      label: tag,
      selected: true,
    })
  })
  return { fundsList, industriesList, customTagsList }
}

export function FilterSelectedView({ filterTagValues }: { filterTagValues: FilterTagValue[] }) {
  return (
    <div className='row pe-0'>
      <div className='col-4 py-2'>Filtered by </div>
      <div className='col-8 p-0'>
        <div className='card bg-transparent w-auto'>
          <div
            className='d-flex flex-row flex-nowrap overflow-auto'
            style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'none' }}
          >
            {filterTagValues.map((filterTagValue, index) => {
              return (
                <span
                  key={index}
                  className='badge border border-primary text-primary bg-primary-fill p-2 px-2 m-1 mh-28px w-auto'
                >
                  {filterTagValue.label}
                  {/* <span>
                    <KTSVG
                      path='/media/icons/duotune/general/gen034.svg'
                      className='svg-icon-6 svg-icon-gray-500 text-primary ps-2'
                    />
                  </span> */}
                </span>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilterMenuView
