import { Link } from 'react-router-dom'
import { formatNumber, kFormatter } from '../../../../utils/CurrencyFormatter'
import { KTSVG } from '../../../../_metronic/helpers'
import { useEffect, useRef, useState } from 'react'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import ManageCompanyTags from './ManageTags'
import { hexToRGB } from '../../../../utils/HexToRgba'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import {
  DeletePrivateCaptableMutation,
  DeletePrivateCaptableMutationVariables,
  useAddTagsToCompanyMutation,
  useDeletePrivateCaptableMutation,
  useGetAllPrivateCaptablesQuery,
  useGetAllTransactionsQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { useCompanyNameStore } from '../../../../store/companyNameStore'
import { DeleteConfirmPopup } from '../../../../utils/DeleteConfirmPopup'

export function CompanyNameRender(data: any) {
  const mutateAddTagsToCompany = useAddTagsToCompanyMutation<Error>(graphqlRequestClient, {}, {})
  const companyNameStore = useCompanyNameStore()
  return (
    <div className='py-2'>
      <div className='d-flex flex-row'>
        {data.data.companyName.name !== 'Total' && (
          <span className='pe-2'>
            {data.data.tags?.includes('Starred') ? (
              <img
                src={'/media/icons/duotune/general/star-filled.svg'}
                alt=''
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  const finalTags = data.data.tags.filter(
                    (tag: string) => tag.toLowerCase() !== 'starred'
                  )
                  mutateAddTagsToCompany.mutate(
                    {
                      input: {
                        companyType: data.data.type,
                        hissaCompanyId: data.data.companyId,
                        companyName: data.data.companyName.name,
                        tags: finalTags,
                        currency: data.data.companyDetails.companyCurrency,
                        currentSharePrice: data.data.companyDetails.sharePrice,
                        jurisdiction: data.data.jurisdiction || 'India',
                      },
                    },
                    {
                      onSuccess: (result) => {
                        queryClient.invalidateQueries(useGetAllTransactionsQuery.getKey())
                        Toast('Company Removed From Starred Companies', ToastType.success)
                      },
                      onError: (error: any) => {
                        Toast(`${error.response.errors[0].message}`, ToastType.error)
                      },
                    }
                  )
                }}
              />
            ) : (
              <img
                src={'/media/icons/duotune/general/star-light.svg'}
                alt=''
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  const finalTags = data.data.tags
                  finalTags.push('Starred')
                  mutateAddTagsToCompany.mutate(
                    {
                      input: {
                        companyType: data.data.type,
                        hissaCompanyId: data.data.companyId,
                        companyName: data.data.companyName.name,
                        tags: finalTags,
                        currency: data.data.companyDetails.companyCurrency,
                        currentSharePrice: data.data.companyDetails.sharePrice,
                        jurisdiction: data.data.jurisdiction || 'India',
                      },
                    },
                    {
                      onSuccess: (result) => {
                        queryClient.invalidateQueries(useGetAllTransactionsQuery.getKey())
                        Toast('Company Added To Starred Companies', ToastType.success)
                      },
                      onError: (error: any) => {
                        Toast(`${error.response.errors[0].message}`, ToastType.error)
                      },
                    }
                  )
                }}
              />
            )}
          </span>
        )}
        <span className='ms-2 mt-1'>
          {data.data.type === 'HISSA' ? (
            <Link
              className='fw-bold text-gray-900 text-hover-primary'
              to={`/company/summary/HISSA/${data.data.companyId}`}
              onClick={() => {
                companyNameStore.setCompanyName(data.data.companyName.name || '')
                companyNameStore.setCompanyCurrency(
                  data.data.companyDetails.companyCurrency || 'INR - ₹'
                )
              }}
            >
              {data.data.companyName.name}{' '}
              {data.data.allPrivateCompanies?.includes(data.data.companyId) && (
                <span
                  className='badge bg-danger rounded-circle fs-9'
                  data-toggle='tooltip'
                  title={'Private Captable'}
                  style={{ maxWidth: '160px' }}
                >
                  P
                </span>
              )}
              {/* <span className='translate-middle ms-1 badge rounded-pill bg-danger'>P</span> */}
              <div className='text-muted fs-8'>{data.data.companyName.dba}</div>
            </Link>
          ) : (
            <Link
              className='fw-bold text-gray-900 text-hover-primary'
              to={`/company/summary/DIRECT/${data.data.companyId}`}
              onClick={() => {
                companyNameStore.setCompanyName(data.data.companyName.name || '')
                companyNameStore.setCompanyCurrency(
                  data.data.companyDetails.companyCurrency || 'INR - ₹'
                )
              }}
            >
              {data.data.companyName.name}
              <div className='text-muted fs-8'>{data.data.companyName.dba}</div>
            </Link>
          )}
        </span>
      </div>
    </div>
  )
}

export function FundRender(data: any) {
  return (
    <div className='py-2'>
      <p>{data.data.fund}</p>
    </div>
  )
}

export function InvestedRoundRender(data: any) {
  return (
    <div className='py-2'>
      <p>{data.data.investedRound}</p>
    </div>
  )
}

export function AmountInvestedRender(data: any) {
  return (
    <div className='py-2'>
      <p>
        {formatNumber({
          value: data.data.amountInvested,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}

export function UnrealizedValueRender(data: any) {
  return (
    <div className='py-2'>
      <p>
        {formatNumber({
          value: data.data.unrealizedValue,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function RealizedValueRender(data: any) {
  return (
    <div className='py-2'>
      <p>
        {formatNumber({
          value: data.data.realizedValue,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}

export function UnrealizedCostRender(data: any) {
  return (
    <div className='py-2'>
      <p>{data.data.unrealizedCost}</p>
    </div>
  )
}

export function FdbPercentageRender(data: any) {
  return (
    <div className='py-2'>
      <p>{data.data.FDBPercentage !== null ? data.data.FDBPercentage.toFixed(2) + '%' : '-'}</p>
    </div>
  )
}

export function FdbSharesRender(data: any) {
  return (
    <div className='py-2'>
      <p>
        {formatNumber({
          value: data.data.FDBShares,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function EsopHoldingRender(data: any) {
  return (
    <div className='py-2'>
      <p>{data.data.esopHolding !== null ? data.data.esopHolding.toFixed(2) + '%' : '-'}</p>
    </div>
  )
}

export function IrrRender(data: any) {
  return (
    <div className='py-2'>
      <p>{data.data.irr !== null ? data.data.irr.toFixed(2) + '%' : '-'}</p>
    </div>
  )
}

export function MoicRender(data: any) {
  return (
    <div className='py-2'>
      <p>{data.data.moic !== null ? data.data.moic.toFixed(2) : '-'}</p>
    </div>
  )
}

export function RoundRender(data: any) {
  return (
    <div className='py-2'>
      <p>{data.data.round}</p>
    </div>
  )
}

export function JurisdictionRender(data: any) {
  return (
    <div className='py-2'>
      <p>{data.data.jurisdiction}</p>
    </div>
  )
}
export function TagsRender(data: any) {
  return (
    <div className='py-2'>
      {data.data.tags &&
        data.data.tags.map((tag: string, index: number) => {
          return (
            <span
              key={index}
              className={`badge fs-9 fw-semibold mb-1 me-2 `}
              style={{
                background: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 0.2),
                color: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 1),
              }}
            >
              {tag}
            </span>
          )
        })}
    </div>
  )
}

export function ActionRender(data: any) {
  const deleteBtnRef = useRef<HTMLButtonElement | null>(null)
  const [showTagsModal, setShowTagsModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [data])

  const mutateRemovePrivateCaptable = useDeletePrivateCaptableMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: DeletePrivateCaptableMutation,
        _variables: DeletePrivateCaptableMutationVariables,
        _context: unknown
      ) => {
        setLoading(false)
        const getAllTransactionQuery = useGetAllTransactionsQuery.getKey()
        queryClient.invalidateQueries(getAllTransactionQuery)
        queryClient.invalidateQueries(useGetAllPrivateCaptablesQuery.getKey())
        Toast('Captable Removed Successfully', ToastType.success)
      },
    },
    {}
  )

  const setShowModalFunction = (state: boolean) => setShowTagsModal(state)
  const editOptions = ['Manage Tags']
  if (data.data.allPrivateCompanies?.includes(data.data.companyId)) {
    editOptions.push('Delete Company')
  }
  const actionMenu = () => {
    let newOptions = editOptions
    return newOptions.map((option) => {
      return (
        <label
          key={option}
          className='form-check-custom py-3 bg-hover-primary text-hover-white'
          onClick={() => {
            if (option === 'Manage Tags') {
              setShowTagsModal(true)
            } else if (option === 'Delete Company') {
              setShowDeleteModal(true)
            }
          }}
        >
          <span className='form-check-label fs-base ms-6 fw-normal cursor-pointer  '>{option}</span>
        </label>
      )
    })
  }

  function handleRemoveCompany(e: React.MouseEvent, companyId: string) {
    e.stopPropagation()
    // Disable indicator after 3 seconds
    deleteBtnRef.current?.setAttribute('data-kt-indicator', 'on')
    setTimeout(() => {
      deleteBtnRef.current?.removeAttribute('data-kt-indicator')
    }, 3000)
    e.preventDefault()
    mutateRemovePrivateCaptable.mutate({ input: { hissaCompanyId: companyId } })
  }

  return (
    <div className='px-0'>
      {data.data.investorName !== 'Total' && data.data.fund !== null && (
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen052.svg'
            className='svg-icon-muted svg-icon-2x'
          />
        </button>
      )}
      {showTagsModal && (
        <ManageCompanyTags
          companyType={data.data.type}
          isIndirect={data.data.isIndirect}
          companyId={data.data.id}
          hissaCompanyId={data.data.companyId}
          companyName={data.data.companyName.name}
          showModal={showTagsModal}
          setShow={setShowModalFunction}
          cin={data.data.cin}
          tags={data.data.tags}
          currency={data.data.companyDetails.companyCurrency}
          currentSharePrice={data.data.companyDetails.sharePrice}
          jurisdiction={data.data.jurisdiction || 'India'}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmPopup
          showModel={showDeleteModal}
          setShowModel={() => setShowDeleteModal(false)}
          loading={loading}
          currentValue={data.data.companyName.name}
          title='Delete Company'
          confirmText={`Enter the Company name "${data.data.companyName.name}" to continue`}
          description='This action will also remove the company from the Hissa platform.'
          primaryAction={(e: React.MouseEvent) => {
            handleRemoveCompany(e, data.data.companyId)
            setShowDeleteModal(false)
          }}
        />
      )}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
        data-kt-menu='true'
      >
        {actionMenu()}
      </div>
    </div>
  )
}
