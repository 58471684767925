import { Link } from 'react-router-dom'
import { formatNumber } from '../../../../utils/CurrencyFormatter'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { getInitials } from '../../../../utils/Utils'
import {
  RemoveCompanyMutation,
  RemoveCompanyMutationVariables,
  useAddOrRemoveStarredCompaniesMutation,
  useGetNewWatchlistsQuery,
  useRemoveCompanyMutation,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { KTSVG } from '../../../../_metronic/helpers'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { useEffect, useRef } from 'react'
import { useCompanyNameStore } from '../../../../store/companyNameStore'

export function CompanyNameRender(data: any) {
  const companyNameStore = useCompanyNameStore()
  const mutateStarredCompany = useAddOrRemoveStarredCompaniesMutation<Error>(
    graphqlRequestClient,
    {},
    {}
  )
  return (
    <div className='pb-2'>
      {data.data.companyId ? (
        <div className='d-flex flex-row'>
          <span className='pe-2 py-2'>
            {data.data.starredCompanies?.includes(data.data.companyId) ? (
              <img
                src={'/media/icons/duotune/general/star-filled.svg'}
                alt=''
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  mutateStarredCompany.mutate(
                    {
                      input: {
                        companyId: data.data.companyId,
                        companyName: data.data.companyName,
                        isRemove: true,
                        watchlistId: data.data.watchlistId,
                      },
                    },
                    {
                      onSuccess: (result) => {
                        queryClient.invalidateQueries(useGetNewWatchlistsQuery.getKey())
                        Toast('Company Removed From Starred Companies', ToastType.success)
                      },
                      onError: (error: any) => {
                        Toast(`${error.response.errors[0].message}`, ToastType.error)
                      },
                    }
                  )
                }}
              />
            ) : (
              <img
                src={'/media/icons/duotune/general/star-light.svg'}
                alt=''
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  mutateStarredCompany.mutate(
                    {
                      input: {
                        companyId: data.data.companyId,
                        companyName: data.data.companyName,
                        isRemove: false,
                        watchlistId: data.data.watchlistId,
                      },
                    },
                    {
                      onSuccess: (result) => {
                        queryClient.invalidateQueries(useGetNewWatchlistsQuery.getKey())
                        Toast('Company Added To Starred Companies', ToastType.success)
                      },
                      onError: (error: any) => {
                        Toast(`${error.response.errors[0].message}`, ToastType.error)
                      },
                    }
                  )
                }}
              />
            )}
          </span>
          <span className=''>
            {data.data.logo && data.data.logo !== '' && data.data.logo !== 'NA' ? (
              <div className='symbol symbol-40px h-40px w-40px symbol-fixed position-relative'>
                <img src={data.data.logo} alt='' />
              </div>
            ) : (
              <div className='bg-secondary rounded h-40px w-40px fw-bold d-flex justify-content-center align-items-center'>
                <p
                  className='mb-0 fs-1'
                  style={{
                    color: getCSSVariableValue('--kt-donut-' + ((1 + 9) % 10)),
                  }}
                >
                  {getInitials(data.data.companyName)}
                </p>
              </div>
            )}
          </span>
          <span className='ps-4 py-2'>
            <Link
              className='fs-base fw-bold mb-0 text-gray-800 text-hover-primary'
              to={`/company/summary/HISSA/${data.data.companyId}`}
              onClick={() => {
                companyNameStore.setCompanyName(data.data.companyName || '')
                companyNameStore.setCompanyCurrency(data.data.companyCurrency || 'INR - ₹')
              }}
            >
              <h6
                className={`${data.data.dba ? '' : 'pt-2'} m-0 fw-bold text-hover-primary`}
                data-toggle='tooltip'
                data-placement='top'
                title={data.data.companyName}
              >
                {data.data.companyName}
              </h6>
            </Link>
            <span className='fs-8 text-muted'>{data.data.dba}</span>
          </span>
        </div>
      ) : (
        <p className='fw-bold'> {data.data.companyName}</p>
      )}
    </div>
  )
}

export function TotalRoundsRoundRender(data: any) {
  return (
    <div className='py-3'>
      <p className='m-0'>{data.data.noOfRound}</p>
    </div>
  )
}

export function ValuationRender(data: any) {
  return (
    <div className='py-3'>
      <p className='m-0'>
        {formatNumber({
          value: data.data.valuation,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function LatestRaiseRender(data: any) {
  return (
    <div className='py-3'>
      <p className='m-0'>
        {formatNumber({
          value: data.data.latestRaise,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function EsopHoldingRender(data: any) {
  return (
    <div className='py-3'>
      <p className='m-0'>
        {formatNumber({
          value: data.data.esopHolding,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function FounderHoldingRender(data: any) {
  return (
    <div className='py-3'>
      <p className='m-0'>
        {formatNumber({
          value: data.data.founderHolding,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}

export function TotalRaisedRender(data: any) {
  return (
    <div className='py-3'>
      <p className='m-0'>
        {formatNumber({
          value: data.data.totalRaise,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}

export function ActionRender(data: any) {
  const deleteBtnRef = useRef<HTMLButtonElement | null>(null)

  const mutateRemoveCompany = useRemoveCompanyMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: RemoveCompanyMutation,
        _variables: RemoveCompanyMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(useGetNewWatchlistsQuery.getKey())
        Toast('Company Removed From Watchlist!', ToastType.success)
      },
    },
    {}
  )

  function handleRemoveCompany(e: React.MouseEvent, watchlistId: number, companyId: string) {
    e.stopPropagation()
    // Disable indicator after 3 seconds
    deleteBtnRef.current?.setAttribute('data-kt-indicator', 'on')
    setTimeout(() => {
      // Activate indicator
      deleteBtnRef.current?.removeAttribute('data-kt-indicator')
    }, 3000)
    e.preventDefault()

    mutateRemoveCompany.mutate({ input: { id: watchlistId, companyId: companyId } })
  }

  return (
    <div className='px-0'>
      <button
        ref={deleteBtnRef}
        className='btn align-center btn-icon btn-sm btn-secondary'
        onClick={(e) => {
          handleRemoveCompany(e, data.data.watchlistId, data.data.companyId)
        }}
      >
        {mutateRemoveCompany.isLoading ? (
          <span className='indicator-progress  '>
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        ) : (
          <KTSVG
            path='media/icons/duotune/general/gen027.svg'
            className='svg-icon-2x   text-primary'
          />
        )}
      </button>
    </div>
  )
}
