/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { AllTransactionsType, WatchlistObject } from '../../../../generated/graphql'
import { getLatestActivityUtils } from '../utils/latestActivityUtils'
import { ActivityType, ActivityTypeObject } from '../utils/ActivityType'
import { isoToDate } from '../../../../utils/FormatDate'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { DateFilterForLatestActivity } from './DateFilterForLatestActivity'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { Link } from 'react-router-dom'
import { formatNumber, getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'
import { GlobalContext } from '../../store/context/globalContext'
import { useLatestActivityStore } from '../../../../store/latestActivityDateStore'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../utils/Select'
import { useCompanyNameStore } from '../../../../store/companyNameStore'

type Props = {
  allTransactions: AllTransactionsType[]
  watchlists: WatchlistObject[]
}

type FilterType = {
  label: string
  value: number
}

function LatestActivityComponent({ allTransactions, watchlists }: Props) {
  const { state } = useContext(GlobalContext)
  const companyNameStore = useCompanyNameStore()
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  const latestActivityStore = useLatestActivityStore()
  const today = new Date()
  const endDatetime = new Date()
  endDatetime.setDate(endDatetime.getDate())
  const [endDate, setEndDate] = useState(new Date(latestActivityStore.endDate) || endDatetime)
  today.setDate(today.getDate() - 90)
  const [startDate, setStartDate] = useState(new Date(latestActivityStore.startDate) || today)

  const [activities, setActivities] = useState<ActivityTypeObject[]>([])

  const [selectedCategory, setSelectedCategory] = useState<FilterType>({
    label: 'All',
    value: 1,
  })

  useEffect(() => {
    let activityData = getLatestActivityUtils(
      allTransactions,
      watchlists,
      startDate.toISOString(),
      endDate.toISOString()
    )
    if (selectedCategory.label === 'Watchlist') {
      activityData = activityData.filter(
        (activity) => activity.type === ActivityType.Create_Watchlist
      )
    } else if (selectedCategory.label === 'Transaction') {
      activityData = activityData.filter(
        (activity) =>
          activity.type === ActivityType.Sell || activity.type === ActivityType.Investment
      )
    } else if (selectedCategory.label === 'Round') {
      activityData = activityData.filter((activity) => activity.type === ActivityType.Round_Raised)
    }
    setActivities(activityData)
    MenuComponent.reinitialization()
  }, [startDate, endDate, selectedCategory])

  const [listView, setListView] = useState(
    GenerateDataRows(activities, userCurrencyFormat, userCurrency, companyNameStore)
  )

  const categoryOptions: FilterType[] = ['All', 'Watchlist', 'Transaction', 'Round'].map(
    (filter: string, index: number) => {
      return {
        value: index + 1,
        label: filter,
      }
    }
  )

  useEffect(() => {
    setListView(GenerateDataRows(activities, userCurrencyFormat, userCurrency, companyNameStore))
    MenuComponent.reinitialization()
  }, [activities])

  return (
    <div
      className='card h-400px m-0 p-0'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <span className='row px-9 pt-8'>
        <span className='col-12 col-md-6'>
          <h2 className='card-label fw-bold mb-1'>Latest Activity</h2>
          {/* <span className='text-muted fw-semibold fs-7'>Top 5 Companies</span> */}
        </span>
        <span className='col-12 col-md-6 d-flex justify-content-end'>
          <span className='w-250px me-3'>
            <Select
              className=''
              options={categoryOptions}
              onChange={(e: any) => setSelectedCategory(e)}
              value={selectedCategory}
              styles={selectCustomStyles}
            />
          </span>
          <span className=''>
            <button
              type='button'
              className={`px-4 rounded border border-1 border-gray-400 bg-gray-200 h-38px text-gray-800 fw-bold`}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              {isoToDate(startDate.toISOString()).replaceAll('-', ' ') +
                ' - ' +
                isoToDate(endDate.toISOString()).replaceAll('-', ' ')}
              {
                <KTSVG
                  path={'/media/icons/duotune/general/gen014.svg'}
                  className='svg-icon-1 svg-icon-gray-500 text-gray-800 ps-6'
                />
              }
            </button>
            <DateFilterForLatestActivity
              startDate={new Date(startDate)}
              endDate={endDate}
              setStartDate={(value) => {
                setStartDate(value)
                latestActivityStore.setStartDate(value)
              }}
              setEndDate={(value) => {
                setEndDate(value)
                latestActivityStore.setEndDate(value)
              }}
            />
          </span>
        </span>
      </span>

      <div className='card px-9 overflow-auto my-9'>
        {listView.props.children.length > 0 ? (
          <div
            className='table-responsive p-0 '
            style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'none' }}
          >
            <table className='table align-middle gs-0 gy-3 table-row-dashed border-gray-200 align-items-baseline'>
              <tbody>{listView}</tbody>
            </table>
          </div>
        ) : (
          <div className='text-center'>
            <img
              alt='no items'
              src={toAbsoluteUrl('/media/icons/duotune/general/empty-watchlist.svg')}
              className='h-200px my-6 w-250 justify-content-center'
            />
            <h4>No activities found in selected date range</h4>
          </div>
        )}
      </div>
    </div>
  )
}

function GenerateDataRows(
  activities: ActivityTypeObject[],
  userCurrencyFormat: string,
  userCurrency: string,
  companyNameStore: any
) {
  return (
    <>
      {activities.map((activity) => {
        if (activity.type === ActivityType.Investment) {
          return (
            <Link
              className='fs-base fw-bold mb-0 text-gray-800 text-hover-primary'
              to={`/company/summary/${activity.companyType}/${
                activity.hissaCompanyId || activity.companyId
              }`}
              onClick={() => {
                companyNameStore.setCompanyName(activity.companyName || '')
                companyNameStore.setCompanyCurrency(activity.companyCurrency || 'INR - ₹')
              }}
            >
              <div className='p-0 bg-hover-secondary rounded'>
                <tr>
                  <td>
                    <span>
                      <KTSVG
                        path={'/media/icons/duotune/communication/com006.svg'}
                        className='px-2 svg-icon-3x text-start'
                      />
                      {<b>{activity.investorName}</b>} invested{' '}
                      {
                        <b
                          data-toggle='tooltip'
                          data-placement='top'
                          title={Number(activity.value).toLocaleString(userCurrencyFormat)}
                        >
                          {getSymbolOfCurrency(userCurrency)}
                          {formatNumber({
                            value: activity.value,
                            format: userCurrencyFormat,
                          })}
                        </b>
                      }{' '}
                      in {<b>{activity.companyName}</b>} on {isoToDate(activity.date)}
                    </span>
                  </td>
                </tr>
              </div>
            </Link>
          )
        } else if (activity.type === ActivityType.Sell) {
          return (
            <Link
              className='fs-base fw-bold mb-0 text-gray-800 text-hover-primary'
              to={`/company/summary/${activity.companyType}/${
                activity.hissaCompanyId || activity.companyId
              }`}
              onClick={() => {
                companyNameStore.setCompanyName(activity.companyName || '')
                companyNameStore.setCompanyCurrency(activity.companyCurrency || 'INR - ₹')
              }}
            >
              <div className='p-0 bg-hover-secondary rounded'>
                <tr>
                  <td>
                    <span>
                      <KTSVG
                        path={'/media/icons/duotune/communication/com006.svg'}
                        className='px-2 svg-icon-3x text-start'
                      />
                      <b>{activity.investorName}</b> sold shares worth{' '}
                      {
                        <b
                          data-toggle='tooltip'
                          data-placement='top'
                          title={Number(activity.value).toLocaleString(userCurrencyFormat)}
                        >
                          {getSymbolOfCurrency(userCurrency)}
                          {formatNumber({
                            value: activity.value,
                            format: userCurrencyFormat,
                          })}
                        </b>
                      }{' '}
                      in <b>{activity.companyName}</b> on {isoToDate(activity.date)}
                    </span>
                  </td>
                </tr>
              </div>
            </Link>
          )
        } else if (activity.type === ActivityType.Round_Raised) {
          return (
            <Link
              className='fs-base fw-bold mb-0 text-gray-800 text-hover-primary'
              to={`/company/summary/${activity.companyType}/${
                activity.hissaCompanyId || activity.companyId
              }`}
              onClick={() => {
                companyNameStore.setCompanyName(activity.companyName || '')
                companyNameStore.setCompanyCurrency(activity.companyCurrency || 'INR - ₹')
              }}
            >
              <div className='p-0 bg-hover-secondary rounded'>
                <tr>
                  <td>
                    <span>
                      <KTSVG
                        path={'/media/icons/duotune/communication/com006.svg'}
                        className='px-2 svg-icon-3x text-start'
                      />
                      <b>{activity.companyName}</b> raised amount{' '}
                      {
                        <b
                          data-toggle='tooltip'
                          data-placement='top'
                          title={Number(activity.value).toLocaleString(userCurrencyFormat)}
                        >
                          {getSymbolOfCurrency(userCurrency)}
                          {formatNumber({
                            value: activity.value,
                            format: userCurrencyFormat,
                          })}
                        </b>
                      }{' '}
                      on {isoToDate(activity.date)}{' '}
                    </span>
                  </td>
                </tr>
              </div>
            </Link>
          )
        } else if (activity.type === ActivityType.Create_Watchlist) {
          return (
            <Link
              className='fs-base fw-bold mb-0 text-gray-800 text-hover-primary'
              to={`/watchlist`}
              state={{ watchlistName: activity.watchlistName }}
            >
              <div className='p-0 bg-hover-secondary rounded'>
                <tr className=''>
                  <td>
                    <span>
                      <KTSVG
                        path={'/media/icons/duotune/communication/com006.svg'}
                        className='px-2 svg-icon-3x text-start'
                      />
                      Created watchlist {<b>{activity.watchlistName}</b>} on{' '}
                      {isoToDate(activity.date)}
                    </span>
                  </td>
                </tr>
              </div>
            </Link>
          )
        } else {
          return <></>
        }
      })}
    </>
  )
}

export default LatestActivityComponent
