import { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'

import { useGetTableFilterState, usePostTableFilterState } from '../api/tableStateApi'
import { FilterConditionModel } from './tableStateIcon'

export const useTableStateManagement = (gridApi: any, tableId: string, pageLoaded: boolean) => {
  const [currentFilterSetting, setCurrentFilterSetting] = useState('')
  const [currentColumnSetting, setCurrentColumnSetting] = useState('')
  const [filterCondition, setFilterCondition] = useState<FilterConditionModel>({
    isColumnSave: true,
    isFilterSave: true,
  })
  const [isSaving, setIsSaving] = useState(false)

  const { data: tableState, refetch } = useGetTableFilterState(tableId)
  const postOnFilter = usePostTableFilterState()

  useEffect(() => {
    if (tableState) {
      const { filterState, columnState, isColumnSave, isFilterSave } = tableState
      setCurrentFilterSetting(JSON.stringify(filterState))
      setCurrentColumnSetting(JSON.stringify(columnState))
      setFilterCondition({
        isColumnSave: isColumnSave ?? true,
        isFilterSave: isFilterSave ?? true,
      })

      if (columnState !== '') {
        setColumnSetting(columnState)
      }
      if (filterState !== '') {
        setFilterSetting(filterState)
      }
    }
  }, [tableState])

  useEffect(() => {
    refetch()
  }, [pageLoaded])

  const getColumnSetting = useCallback(() => {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState()
    else return {}
  }, [gridApi.current, pageLoaded])

  const setColumnSetting = useCallback(
    (model: Record<string, unknown>): void => {
      if (gridApi.current)
        gridApi.current.columnApi?.applyColumnState({ state: model, applyOrder: true })
    },
    [gridApi.current, pageLoaded]
  )

  const getFilterSetting = useCallback(() => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel()
    else return {}
  }, [gridApi.current, pageLoaded])

  const setFilterSetting = useCallback(
    (model: Record<string, unknown>): void => {
      if (gridApi.current) gridApi.current.api?.setFilterModel(model)
    },
    [gridApi.current, pageLoaded]
  )

  const updatedCloud = useCallback(async (): Promise<void> => {
    const columnState = await getColumnSetting()
    const filterState = await getFilterSetting()
    const newColumnState = JSON.stringify(columnState)
    const newFilterState = JSON.stringify(filterState)

    if (currentFilterSetting !== newFilterState || currentColumnSetting !== newColumnState) {
      debouncedSearch(filterState, columnState)
    }
  }, [currentFilterSetting, currentColumnSetting, getColumnSetting, getFilterSetting])

  useEffect(() => {
    if (gridApi.current) {
      const handleSortChange = () => {
        updatedCloud()
      }

      gridApi.current.api.addEventListener('sortChanged', handleSortChange)

      return () => {
        gridApi.current.api.removeEventListener('sortChanged', handleSortChange)
      }
    }
  }, [gridApi.current, updatedCloud])

  const uploadFilter = useCallback(async (): Promise<void> => {
    if (filterCondition.isFilterSave) await updatedCloud()
  }, [filterCondition.isFilterSave, updatedCloud])

  const uploadColumn = useCallback(async (): Promise<void> => {
    if (filterCondition.isColumnSave) await updatedCloud()
  }, [filterCondition.isColumnSave, updatedCloud])

  const debouncedSearch = useCallback(
    _.debounce((filterState: Record<string, unknown>, columnState: Record<string, unknown>) => {
      setIsSaving(true)
      postOnFilter.mutate(
        {
          tableId,
          companyId: '',
          filterData: {
            isColumnSave: filterCondition.isColumnSave,
            isFilterSave: filterCondition.isFilterSave,
            filterState: filterCondition.isFilterSave
              ? filterState
              : JSON.parse(currentFilterSetting),
            columnState: filterCondition.isColumnSave
              ? columnState
              : JSON.parse(currentColumnSetting),
          },
        },
        {
          onSuccess: () => {
            setTimeout(() => {
              setIsSaving(false)
            }, 2000)
          },
          onError: () => {
            setIsSaving(false)
          },
        }
      )
    }, 1000),
    [filterCondition, currentFilterSetting, currentColumnSetting, postOnFilter]
  )

  return {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
    gridApi,
    tableState,
  }
}
