import axios, { AxiosError, AxiosRequestHeaders } from 'axios'
import { queryClient } from './client'
import { useAuthorizationStore } from '../store/useAuthStore'
import { revalidateAuth } from '../utils/auth'

export const capTableTableStateApi = axios.create({
  baseURL: process.env.REACT_APP_CAPTABLE_URL || 'https://dev-captable-api.hissa.com',
})

capTableTableStateApi.defaults.headers.post['Content-Type'] = 'application/json'

capTableTableStateApi.interceptors.request.use(
  (config) => {
    const token =
      sessionStorage.getItem('accessToken') || JSON.parse(localStorage.getItem('jwt') || '') // Retrieve token from sessionStorage

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `${token}`, // Add the Authorization header
      }
    }

    return config
  },
  (err) => Promise.reject(err)
)

// capTableApi.interceptors.response.use(
//   (res) => {
//     // if (res.status === 401) {
//     //   return Promise.reject(res)
//     // }
//     return res
//   },
//   (error: AxiosError) => {
//     return Promise.reject(error)
//   }
// )
