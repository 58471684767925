import { useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import {
  AddCompanyMutation,
  AddCompanyMutationVariables,
  GlobalCompanySearchType,
  WatchlistObject,
  useAddCompanyMutation,
  useGetNewWatchlistsQuery,
} from '../../../../generated/graphql'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { useCompanyNameStore } from '../../../../store/companyNameStore'

type SearchCompaniesProps = {
  filteredCompanies: GlobalCompanySearchType[]
  currentWatchlist: WatchlistObject
}

export function GlobalSearchBar(props: SearchCompaniesProps) {
  const companyNameStore = useCompanyNameStore()
  const getNewWatchlistQueryKey = useGetNewWatchlistsQuery.getKey()

  const mutateAddCompany = useAddCompanyMutation<Error>(graphqlRequestClient, {
    onSuccess: (
      data: AddCompanyMutation,
      _variables: AddCompanyMutationVariables,
      _context: unknown
    ) => {
      queryClient.invalidateQueries(getNewWatchlistQueryKey)
      Toast('Company Added To Watchlist Successfully', ToastType.success)
    },
    onError: (error: any) => {
      Toast(`${error.response.errors[0].message}`, ToastType.error)
    },
  })

  function handleAddCompany(e: React.MouseEvent, companyId: string) {
    e.stopPropagation()
    e.preventDefault()
    mutateAddCompany.mutate({
      input: {
        id: props.currentWatchlist.id,
        companyId: companyId,
      },
    })
  }

  const serachedResults = props.filteredCompanies?.map(
    (company: GlobalCompanySearchType, index: number) => {
      return (
        <div className='d-flex flex-row'>
          <span className='pe-2 p-0 py-2'>
            {props.currentWatchlist && (
              <button
                type='button'
                className=' bg-white border border-0'
                onClick={(e) => {
                  handleAddCompany(e, company.id!)
                }}
              >
                <KTSVG
                  path={'media/icons/duotune/general/gen041.svg'}
                  className='ps-4 svg-icon-2x text-start fs-9 pt-3'
                  svgClassName='text-gray-700 text-hover-primary'
                />
              </button>
            )}
          </span>

          <Link
            className='fs-base fw-bold text-gray-800 text-hover-primary d-flex p-0 justify-content-center'
            to={`/company/summary/HISSA/${company.id}`}
            onClick={() => {
              companyNameStore.setCompanyName(company.name || '')
              companyNameStore.setCompanyCurrency(company.companyCurrency || 'INR - ₹')
            }}
          >
            <tr key={index} className='bg-hover-primary-light py-2'>
              <td className='w-350px min-w-lg-650px px-2'>
                <p className='mb-0'>{company.name}</p>
                <div className='fs-8 text-gray-500'>{company.dba}</div>
              </td>
            </tr>
          </Link>
        </div>
      )
    }
  )

  return (
    <>
      <div className='separator'></div>
      {props.filteredCompanies.length > 0 && (
        <div className='card w-675px mh-325px bg-white overflow-auto p-1'>
          <table className='table align-middle table-row-dashed align-items-baseline'>
            <tbody>{serachedResults}</tbody>
          </table>
        </div>
      )}
    </>
  )
}
