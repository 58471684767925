import { useContext } from 'react'
import {
  AllTransactionsType,
  CompanyObject,
  CompanyTypes,
  WatchlistObject,
} from '../../../../generated/graphql'
import { formatNumber, getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'
import { GlobalContext } from '../../store/context/globalContext'
import { Link, useNavigate } from 'react-router-dom'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { getInitials } from '../../../../utils/Utils'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useCompanyNameStore } from '../../../../store/companyNameStore'

type StarredCompaniesProps = {
  starredCompanies: string[]
  watchlist: WatchlistObject[]
  logo?: string
  allTransactions: AllTransactionsType[]
}

export function WatchlistStarredCompanies(props: StarredCompaniesProps) {
  const { state } = useContext(GlobalContext)
  const companyNameStore = useCompanyNameStore()
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  const allCompanies = getCompanies(props.watchlist, props.starredCompanies, props.allTransactions)
  const navigate = useNavigate()

  const tableHeader = () => {
    return (
      <tr className='fw-bold text-gray-600'>
        <th className='min-w-125px'></th>
        <th className='min-w-50px text-end pe-0'>Valuation</th>
        <th className='min-w-50px text-end pe-0'>Share Price</th>
      </tr>
    )
  }

  const StarredCompaniesTableData = allCompanies?.map((company, index: number) => {
    return (
      <tr key={index}>
        <td className='min-w-100px mw-125px'>
          <div className='d-flex flex-row'>
            <span className=''>
              {company?.logo && company?.logo !== '' && company?.logo !== 'NA' ? (
                <div className='symbol symbol-40px h-40px w-40px symbol-fixed position-relative'>
                  <img src={company?.logo} alt='' />
                </div>
              ) : (
                <div className='bg-secondary rounded h-40px w-40px fw-bold d-flex justify-content-center align-items-center'>
                  <p
                    className='mb-0 fs-1'
                    style={{
                      color: getCSSVariableValue('--kt-donut-' + ((index + 9) % 10)),
                    }}
                  >
                    {getInitials(company?.name || '')}
                  </p>
                </div>
              )}
            </span>
            <span className='ps-4'>
              <Link
                className='fs-base fw-bold mb-0 text-gray-800 text-hover-primary'
                to={`/company/summary/${company?.type}/${company?.hissaCompanyId || company?.id}`}
                onClick={() => {
                  companyNameStore.setCompanyName(company?.name || '')
                  companyNameStore.setCompanyCurrency(company.companyCurrency || 'INR - ₹')
                }}
              >
                <h6
                  className='pt-4 m-0 text-truncate fw-bold text-hover-primary'
                  data-toggle='tooltip'
                  data-placement='top'
                  title={company?.name?.toUpperCase()}
                  style={{ maxWidth: '95px' }}
                >
                  {company?.name?.toUpperCase()}
                </h6>
              </Link>
              <span className='text-muted'>{company.dba}</span>
            </span>
          </div>
        </td>
        <td className='pe-0 pt-3'>
          <p
            data-toggle='tooltip'
            data-placement='top'
            title={Number(company?.valuation).toLocaleString(userCurrencyFormat)}
            className='fs-base text-end fw-bold mb-0'
          >
            {getSymbolOfCurrency(userCurrency)}{' '}
            {formatNumber({
              value: Number(company?.valuation),
              format: userCurrencyFormat,
            })}
          </p>
        </td>
        <td className='pt-3'>
          <p
            data-toggle='tooltip'
            data-placement='top'
            title={Number(company?.sharePrice).toLocaleString(userCurrencyFormat)}
            className='fs-base text-end fw-bold mb-0'
          >
            {getSymbolOfCurrency(userCurrency)}{' '}
            {formatNumber({
              value: Number(company?.sharePrice),
              format: userCurrencyFormat,
            })}
          </p>
        </td>
      </tr>
    )
  })

  return (
    <div
      className='card h-400px m-0 p-0'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <span className='px-9 pt-8'>
        <h2 className='card-label fw-bold mb-1'>Starred Companies</h2>
        {/* <span className='text-muted fw-semibold fs-7'>Top 5 Companies</span> */}
      </span>

      <div className='card px-9 overflow-auto my-9'>
        {allCompanies.length > 0 ? (
          <div
            className='table-responsive p-0 '
            style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'none' }}
          >
            <table className='table align-middle gs-0 gy-3 table-row-dashed border-gray-200 align-items-baseline'>
              <thead>{tableHeader()}</thead>
              <tbody>{StarredCompaniesTableData}</tbody>
            </table>
          </div>
        ) : (
          <div className='text-center'>
            <img
              alt='no items'
              src={toAbsoluteUrl('/media/custom-images/emptyStarredCompanies.svg')}
              className='h-150px my-6 w-200 justify-content-center'
            />
            <h4>Mark companies as favourites in watchlist</h4>
            <div className='fw-semibold fs-6'>Click below to star the companies</div>
            <button
              type='button'
              className='btn btn-primary mt-4'
              onClick={() => {
                navigate('/watchlist')
              }}
            >
              Go to Watchlist
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

function getCompanies(
  watchlistData: WatchlistObject[],
  starredCompanies: string[],
  allTransactions: AllTransactionsType[]
) {
  const allCompanies: CompanyObject[] = []
  allTransactions.forEach((company) => {
    if (company.companyData.tags && company.companyData.tags.includes('Starred')) {
      if (company.companyData.type === CompanyTypes.Hissa) {
        if (
          allCompanies.findIndex(
            (companies) => companies.hissaCompanyId === company.companyData.hissaCompanyId
          ) === -1
        ) {
          allCompanies.push(company.companyData as CompanyObject)
        }
      } else {
        allCompanies.push(company.companyData as CompanyObject)
      }
    }
  })
  watchlistData?.forEach((watchlist) => {
    watchlist?.companies?.forEach((company) => {
      if (
        starredCompanies.includes(company?.companyData.hissaCompanyId as string) &&
        allCompanies.findIndex(
          (companies) => companies.hissaCompanyId === company?.companyData.hissaCompanyId
        ) === -1
      ) {
        allCompanies.push(company?.companyData as CompanyObject)
      }
    })
  })
  return allCompanies
}
