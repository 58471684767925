import { AgGridReact } from 'ag-grid-react'
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { agConfigSideBar, tableCellStyle } from '../../../../../utils/AgGridConfig'
import { ColDef } from 'ag-grid-community'
import {
  AllTransactionsType,
  CompanyObject,
  CompanyTypes,
  SetDefaultValuationMutation,
  SetDefaultValuationMutationVariables,
  ValuationDetailsObject,
  useGetAllTransactionsQuery,
  useSetDefaultValuationMutation,
} from '../../../../../generated/graphql'

import { GlobalContext } from '../../../store/context/globalContext'
import { getCurrency, getSymbolOfCurrency } from '../../../../../utils/CurrencyFormatter'
import { KTSVG } from '../../../../../_metronic/helpers'
import {
  DateOfValuationRender,
  MethodologyRender,
  SharePriceRender,
  TagsRender,
  ValuationActionRender,
  ValuationNameRender,
  ValuationReportRender,
  ValuationStatusRender,
  ValuationTypeRender,
} from './ValuationTableAGComponents'
import AddHissaValuationDetailModal from '../../../manual-investments/components/AddValuationDetails/AddHissaValuationDetailsModal'
import Toast, { ToastType } from '../../../../../utils/toast'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import CloudSetting from '../../../../../utils/tableStateIcon'
import { useTableStateManagement } from '../../../../../utils/TableHook'
import { TableId } from '../../../../../utils/TableIdConstants'

type TransactionProps = {
  data: AllTransactionsType
}

export function ValuationDetailsAGTable(props: TransactionProps) {
  const gridApi = useRef<any>(null)
  const setDefaultBtnRef = useRef<HTMLButtonElement | null>(null)
  const valuationData: ValuationDetailsObject[] = props.data
    .valuationDetailsData as ValuationDetailsObject[]

  const companyDetails = props.data.companyData as CompanyObject
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  const [pageLoaded, setPageLoaded] = useState(false)
  const companyId = companyDetails?.hissaCompanyId || companyDetails?.id
  const { filterCondition, setFilterCondition, isSaving, uploadFilter, uploadColumn, tableState } =
    useTableStateManagement(gridApi, TableId.valuationReportTable + companyId + 'web', pageLoaded)

  const componentsRegistry = useMemo(
    () => ({
      valuationName: memo(ValuationNameRender),
      valuationType: memo(ValuationTypeRender),
      valuationStatus: memo(ValuationStatusRender),
      sharePrice: memo(SharePriceRender),
      methodology: memo(MethodologyRender),
      dateOfValuation: memo(DateOfValuationRender),
      valuationReport: memo(ValuationReportRender),
      tags: memo(TagsRender),
    }),
    []
  )

  const showNoRowsOverlay = () => (
    <p className='fs-base text-muted text-center'>No Valuation reports found in this company.</p>
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const [filteredRowData, setFilteredRowData] = useState<any>([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent()
    setIsFilterApplied(filtersApplied)

    const filteredData = grid.api.getModel().rowsToDisplay.map((node: any) => node.data)
    setFilteredRowData(filteredData)

    uploadFilter()
  }

  useEffect(() => {
    const filterModel = gridApi.current?.api.getFilterModel()

    const filteredData = gridApi.current?.api.getModel().rowsToDisplay.map((node: any) => node.data)

    setFilteredRowData(filteredData)
    gridApi.current?.api.setFilterModel(tableState?.filterState)
  }, [gridApi, tableState?.filterState, tableState?.columnState, valuationData])

  const [showModal, setShowModal] = useState<boolean>(false)
  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'valuationName',
        cellRenderer: 'valuationName',
        cellStyle: tableCellStyle,
        pinned: 'left',
        initialWidth: 250,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Valuation Status',
        field: 'valuationStatus',
        // onCellClicked: (e: any) => {

        // },
        filter: 'agNumberColumnFilter',
        cellRenderer: 'valuationStatus',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },

      {
        headerName: 'Date',
        field: 'dateOfValuation',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0
            }
            const dateParts = cellValue.split('-')
            const day = Number(dateParts[2]?.split('T')[0])
            const month = Number(dateParts[1]) - 1
            const year = Number(dateParts[0])
            const cellDate = new Date(year, month, day)
            if (cellDate < dateFromFilter) {
              return -1
            } else if (cellDate > dateFromFilter) {
              return 1
            }
            return 0
          },
        },
        cellRenderer: 'dateOfValuation',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },

      {
        headerName: 'Valuation Type',
        field: 'valuationType',
        filter: 'agTextColumnFilter',
        cellRenderer: 'valuationType',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },

      {
        headerName: `Share Price (${getSymbolOfCurrency(
          props.data.companyData.companyCurrency || userCurrency
        )})`,
        field: 'sharePrice',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'sharePrice',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipSharePrice',
      },

      {
        headerName: 'Methodology',
        field: 'methodology',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'methodology',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },

      {
        headerName: 'Tags',
        field: 'tags',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'tags',
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Valuation Report',
        field: 'valuationReport',
        cellRenderer: 'valuationReport',
        cellStyle: tableCellStyle,
        initialWidth: 250,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: '',
        field: 'action',
        pinned: 'right',
        hide: false,
        width: 60,
        maxWidth: 60,
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: {
          'padding-left': '9px',
          'padding-right': '4px',
          'padding-top': '3px',
          'line-height': '20px',
        },
        resizable: false,
        sortable: false,
        // cellRendererParams: ({ value }: { value: any }) => {
        //   return value.props
        // },
        cellRenderer: ValuationActionRender,
      },
    ],
    [userCurrencyFormat]
  )

  const rowData = useMemo(() => {
    if (!valuationData) return []
    setPageLoaded(true)
    return valuationData?.map((valuation: ValuationDetailsObject, index: number) => {
      return {
        valuationName: valuation?.valuationName,
        valuationType: valuation.valuationType,
        methodology: valuation.methodology,
        sharePrice: Math.abs(valuation.sharePricePerReport!),
        tooltipSharePrice: Math.abs(valuation.sharePricePerReport!).toLocaleString(
          getCurrency(props.data.companyData.companyCurrency || userCurrencyFormat)
        ),
        dateOfValuation: valuation.valuationDate,
        tags: valuation.tags,
        valuationReport: valuation.fileUrl,
        userCurrencyFormat: getCurrency(
          props.data.companyData.companyCurrency || userCurrencyFormat
        ),
        userCurrency: getCurrency(props.data.companyData.companyCurrency || userCurrencyFormat),
        valuation: valuation,
        index: index,
        investorDetails: props.data.investorData,
        companyDetails: companyDetails,
        // action: <ValuationActionRender onCLick={(e: any) => console.log(e)} data={valuation} />,
      }
    })
  }, [valuationData])

  return (
    <div
      className='card p-0 h-600px'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='d-flex flex-row border-0 p-9'>
        <div className='card-title align-items-end flex-column'>
          {/* <h1 className='m-0'>My Transactions</h1>
          <span className='text-muted'>
            {valuationData?.length > 0 ? `${valuationData?.length} Transactions` : ''}
          </span> */}
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          <CloudSetting
            tableId={TableId.valuationReportTable + companyId + 'web'}
            isLoading={isSaving}
            filterCondition={filterCondition}
            setFilterCondition={setFilterCondition}
          />
          <button
            type='button'
            className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_1'
            onClick={() => {
              setShowModal(!showModal)
            }}
          >
            Add Valuation
          </button>
          {showModal && (
            <AddHissaValuationDetailModal
              showModal={showModal}
              handleClose={() => setShowModal(false)}
              companyDetails={props.data.companyData}
            />
          )}
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          onFilterChanged={onAgGridFilterChanged}
          onColumnMoved={uploadColumn}
          onColumnEverythingChanged={uploadColumn}
          onColumnResized={uploadColumn}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={true}
          enableRangeSelection={false}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          noRowsOverlayComponent={showNoRowsOverlay}
          suppressMenuHide={false}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
