import { ChangeEvent, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import * as Yup from 'yup'

import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import {
  AddTagsToInvestmentMutation,
  AddTagsToInvestmentMutationVariables,
  CompanyObject,
  TransactionObject,
  useAddTagsToTransactionMutation,
  useGetCompanyWiseDataQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { selectCustomStyles } from '../../../../utils/Select'
import { InputBox } from '../../../../utils/NumberInputBox'
import { useFormik } from 'formik'
import { currencies } from '../../../../utils/CurrencyList'
import { getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'

type Props = {
  companyDetails: CompanyObject
  transactionDetails: TransactionObject
  hissaCompanyId: string
  investorId?: bigint
  tags?: string[]
  showModal: boolean
  setShow: Function
  investmentId?: bigint
  hissaRecordId?: string
  indirectInvestmentId?: bigint
  transactionCurrency?: string
  transactionAmount?: number
  noOfUnits?: number
}
type SelectOptionType = { label?: string; value?: string }

export type CompanyMetadataType = {
  investmentManagerName: string
  email: string
  demat: string
  isForeignTransaction: string
  transactionAmount?: number
  transactionCurrency?: string
}

export default function ManageTransactionMetadata(props: Props) {
  const initValues: CompanyMetadataType = {
    investmentManagerName: props.transactionDetails?.metadata?.investmentManagerName || '',
    email: props.transactionDetails.metadata?.email || '',
    demat: props.transactionDetails.metadata?.demat || '',
    isForeignTransaction: props.transactionDetails.metadata?.isForeignTransaction || '',
    transactionAmount: props.transactionDetails.metadata?.transactionAmount || 0,
    transactionCurrency: props.transactionDetails.metadata?.transactionCurrency || '',
  }
  const [isDemat, setIsDemat] = useState<any>({
    value: props.transactionDetails.metadata?.demat || 'No',
    label: props.transactionDetails.metadata?.demat || 'No',
  })
  const [isForeignInvestment, setIsForeignInvestment] = useState<any>({
    value: props.transactionDetails.metadata?.isForeignTransaction || 'No',
    label: props.transactionDetails.metadata?.isForeignTransaction || 'No',
  })

  const [currency, setCurrency] = useState<any>({
    value: props.transactionDetails.metadata?.transactionCurrency || 'USD - $',
    label: props.transactionDetails.metadata?.transactionCurrency || 'USD - $',
  })

  const currencyList: SelectOptionType[] = []
  currencies.forEach((currency: string) => {
    currencyList.push({
      value: currency,
      label: currency,
    })
  })

  const [loading, setLoading] = useState<boolean>(false)

  const mutateAddMetadataToTransaction = useAddTagsToTransactionMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddTagsToInvestmentMutation,
        _variables: AddTagsToInvestmentMutationVariables,
        _context: unknown
      ) => {
        const compKey = {
          input: {
            type: props.companyDetails.type,
            hissaCompanyId: props.companyDetails.hissaCompanyId || '',
            id: Number(props.companyDetails.id) || 0,
          },
        }
        const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
        queryClient.invalidateQueries(getCompanyWiseQuery)
        Toast('Transaction Details Updated Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        setLoading(false)
        Toast('Error updating Transaction Details', ToastType.error)
      },
    },
    {}
  )

  const dematOptionList = ['Yes', 'No', 'Applied']
  const optionList = ['Yes', 'No', 'Applied']
  const dematOption: SelectOptionType[] = []
  const isForeignOption: SelectOptionType[] = []
  dematOptionList.forEach((option: string) => {
    dematOption.push({
      value: option,
      label: option,
    })
  })
  optionList.forEach((option: string) => {
    isForeignOption.push({
      value: option,
      label: option,
    })
  })

  const companyValidationSchema = Yup.object().shape({
    // name: Yup.string().required().label('InvestmentManagerName'),
    email: Yup.string().email().label('Email'),
  })

  const formik = useFormik<CompanyMetadataType>({
    initialValues: initValues,
    validationSchema: companyValidationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        const metadata: any = {
          investmentManagerName: values.investmentManagerName,
          email: values.email,
          demat: values.demat || isDemat?.value,
          isForeignTransaction: values.isForeignTransaction || isForeignInvestment?.value,
          transactionAmount: values.isForeignTransaction === 'Yes' ? values.transactionAmount : 0,
          transactionCurrency:
            values.isForeignTransaction === 'Yes' ? values.transactionCurrency : '',
        }

        mutateAddMetadataToTransaction.mutate({
          input: {
            companyType: props.companyDetails.type,
            hissaCompanyId: props.hissaCompanyId,
            companyId: props.companyDetails.id,
            investmentId: props.investmentId,
            hissaRecordId: props.hissaRecordId,
            indirectInvestmentId: props.indirectInvestmentId,
            companyName: props.companyDetails.name,
            tags: props.transactionDetails.tags,
            investorId: props.investorId,
            currency: props.transactionDetails.currency || props.transactionCurrency || 'INR - ₹',
            exchangeRate: 1,
            investedAmount: props.transactionDetails.amount || props.transactionAmount || 0,
            investmentDate: props.transactionDetails.transactionDate?.split('T')[0],
            noOfShares: props.transactionDetails.noOfShares || props.noOfUnits || 0,
            sharePrice: props.companyDetails.sharePrice || 1,
            jurisdiction: props.companyDetails.jurisdiction || 'India',
            securitySubType: props.transactionDetails.securitySubType || 'Others',
            notes: props.transactionDetails.notes || '',
            metadata: metadata,
          },
        })
      }, 1000)
    },
  })

  return (
    <Modal
      id='kt_header_search_modal'
      aria-hidden='true'
      tabIndex={-1}
      dialogClassName='modal-dialog modal-dialog-centered h-auto mw-800px '
      show={props.showModal}
      centered
      animation={true}
      backdrop={true}
    >
      <Modal.Header className='p-9 pb-2'>
        <Modal.Title className='fs-1 text-gray-heading'>
          <div>Manage Transaction Details</div>
          <div className='fs-8 text-gray-500'>Add Metadata to investment Record</div>
        </Modal.Title>

        <button
          className='btn btn-sm btn-icon btn-active-light-primary '
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={() => {
            props.setShow(false)
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </button>
      </Modal.Header>
      <Modal.Body className='p-9'>
        <div className='row'>
          <div className='fv-row mb-10 col-12 col-sm-6 pe-6'>
            <label className='form-label'>Investment Manager</label>
            <InputBox
              isDisabled={false}
              name={'investmentManagerName'}
              handleChange={(value: string) => {
                formik.setFieldValue('investmentManagerName', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('investmentManagerName', value)
              }}
              placeholder={'Enter Investment Manager Name'}
              currency={props.transactionCurrency}
              value={formik.values.investmentManagerName}
              type='text'
            />
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 pe-6'>
            <label className='form-label'>Email</label>
            <InputBox
              isDisabled={false}
              name={'email'}
              handleChange={(value: string) => {
                formik.setFieldValue('email', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('email', value)
              }}
              placeholder={'Enter Email'}
              currency={props.transactionCurrency}
              value={formik.values.email}
              type='text'
            />
            <div className='text-danger fs-7 mt-1'>
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.email}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 pe-6'>
            <label className='form-label'>Demat</label>

            <Select
              options={dematOption}
              value={isDemat}
              name='currency'
              onChange={(e: any) => {
                setIsDemat(e)
                formik.setFieldValue('demat', e.value)
              }}
              styles={selectCustomStyles}
            />
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 pe-6'>
            <label className='form-label'>FCTRS/FCGPR Filed</label>

            <Select
              options={isForeignOption}
              value={isForeignInvestment}
              name='currency'
              onChange={(e: any) => {
                setIsForeignInvestment(e)
                formik.setFieldValue('isForeignTransaction', e.value)
              }}
              styles={selectCustomStyles}
            />
          </div>

          {formik.values.isForeignTransaction && formik.values.isForeignTransaction === 'Yes' && (
            <>
              <div className='fv-row mb-10 col-12 col-sm-6 pe-6'>
                <label className='form-label'>Transaction Currency</label>
                <Select
                  options={currencyList.filter((value) => Object.keys(value).length !== 0)}
                  value={currency}
                  name='transactionCurrency'
                  onChange={(e: any) => {
                    setCurrency(e)
                    formik.setFieldValue('transactionCurrency', e.value)
                  }}
                  styles={selectCustomStyles}
                />
              </div>
              <div className='fv-row mb-10 col-12 col-sm-6 pe-6'>
                <label className='form-label'>
                  Transaction Amount
                  <span style={{ fontFamily: 'arial' }}>
                    ({getSymbolOfCurrency(currency.label)})
                  </span>
                </label>

                <InputBox
                  isDisabled={false}
                  name={'transactionAmount'}
                  handleChange={(value: string) => {
                    formik.setFieldValue('transactionAmount', value)
                  }}
                  setFieldValue={(value: string) => {
                    formik.setFieldValue('transactionAmount', value)
                  }}
                  placeholder={'Invested Amount'}
                  currency={formik.values.transactionCurrency}
                  value={formik.values.transactionAmount || 0}
                  type='number'
                />
              </div>
            </>
          )}
        </div>

        <div className='col-12 d-flex justify-content-end mt-4'>
          <button
            type='submit'
            className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Saving...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className='p-9 pt-0 border-0'>
        <div className='row justify-content-end'>
          <button
            type='submit'
            onClick={() => {
              addTagsToInvestment()
            }}
            className='btn btn-lg btn-primary w-100 mt-3'
          >
            {!mutateAddTagsToTransaction.isLoading && 'Save'}
            {mutateAddTagsToTransaction.isLoading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Saving...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </Modal.Footer> */}
    </Modal>
  )
}
