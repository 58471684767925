import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import { formatNumber } from '../../../../../utils/CurrencyFormatter'
import { hexToRGB } from '../../../../../utils/HexToRgba'

export function ShareHolderNameRender(data: any) {
  return (
    <div className='pt-2 d-flex flex-row'>
      <p className='fw-bold'> {data.data.shareholderName}</p>
      {data.data.amountInvested !== 0 && data.data.shareholderName !== 'Total' && (
        <span
          className={`badge fs-9 fw-semibold ms-2 h-20px `}
          style={{
            background: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.3),
            color: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 1),
          }}
        >
          {'New Investor'}
        </span>
      )}
    </div>
  )
}

export function SecuritiesRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.security}</p>
    </div>
  )
}

export function PreHoldingPercentageRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.preHoldingPercentage,
          format: data.data.companyCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function PostHoldingPercentageRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.postHoldingPercentage,
          format: data.data.companyCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function PreHoldingSharesRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.preHoldingShares,
          format: data.data.companyCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function PostHoldingSharesRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.postHoldingShares,
          format: data.data.companyCurrencyFormat,
        })}
      </p>
    </div>
  )
}

export function AmountRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.amountInvested,
          format: data.data.companyCurrencyFormat,
        })}
      </p>
    </div>
  )
}
