import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridApi } from 'ag-grid-community'
import { useGetTermsheetsQuery, GetTermsheetsQuery } from '../../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../../queries/client'
import { isoToDate } from '../../../../../utils/FormatDate'
import { kFormatter } from '../../../../../utils/CurrencyFormatter'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { DocumentNameRender, SentStatusRender } from './DealsAGTableComponents'
import { DealsActionRender } from './DealsAGActionMenu'
import { KTSVG } from '../../../../../_metronic/helpers'
import { agConfigSideBar } from '../../../../../utils/AgGridConfig'
import _ from 'lodash'
import CloudSetting from '../../../../../utils/tableStateIcon'
import { TableId } from '../../../../../utils/TableIdConstants'
import { useTableStateManagement } from '../../../../../utils/TableHook'

function DealsAGTable() {
  const gridApi = useRef<any>(null)
  const { data } = useGetTermsheetsQuery<GetTermsheetsQuery, Error>(
    graphqlRequestClient,
    {},
    {},
    {}
  )
  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  let active = 0
  let draft = 0
  let lapsed = 0
  data?.termsheets?.forEach((termsheet) => {
    if (termsheet.status === 'Active') {
      active = active + 1
    } else if (termsheet.status === 'Draft') {
      draft = draft + 1
    } else {
      lapsed = lapsed + 1
    }
  })

  const [pageLoaded, setPageLoaded] = useState(false)
  const { filterCondition, setFilterCondition, isSaving, uploadFilter, uploadColumn, tableState } =
    useTableStateManagement(gridApi, TableId.dealsTable + 'web', pageLoaded)

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const [filteredRowData, setFilteredRowData] = useState<any>([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent()
    setIsFilterApplied(filtersApplied)

    const filteredData = grid.api.getModel().rowsToDisplay.map((node: any) => node.data)
    setFilteredRowData(filteredData)

    uploadFilter()
  }

  useEffect(() => {
    const filterModel = gridApi.current?.api.getFilterModel()

    const filteredData = gridApi.current?.api.getModel().rowsToDisplay.map((node: any) => node.data)

    setFilteredRowData(filteredData)
    gridApi.current?.api.setFilterModel(tableState?.filterState)
  }, [gridApi, tableState?.filterState, tableState?.columnState, data?.termsheets])

  const rowData = useMemo(() => {
    setPageLoaded(true)
    return (
      data?.termsheets &&
      data?.termsheets.map((termsheet) => {
        let result: any = {}
        _.forEach(termsheet.sections, (value, key) => {
          result = { ...result, ...value }
        })
        const date: string[] = result?.expiryDate ? result?.expiryDate.split('-') : []
        const expiryDate =
          date.length > 0
            ? date[0].length === 4
              ? isoToDate(`${date[0]}-${date[1]}-${date[2]}`)
              : isoToDate(`${date[2]}-${date[1]}-${date[0]}`)
            : '-'
        return {
          termsheetName: termsheet.termsheetName,
          status: termsheet.status,
          sentStatus: termsheet.isSentToCompany ? 'Yes' : 'No',
          round: result?.roundSize ? kFormatter(Number(result?.roundSize)) : '-',
          investedAmount: result?.investmentAmount
            ? kFormatter(Number(result?.investmentAmount))
            : '-',
          issueDate: termsheet.createdAt ? isoToDate(termsheet.createdAt) : '-',
          validity: expiryDate,
          ...termsheet,
          isSentToCompany: termsheet.isSentToCompany,
          termsheetType: termsheet.type,
          updatedAt: termsheet.updatedAt ? isoToDate(termsheet.updatedAt) : '-',
        }
      })
    )
  }, [data?.termsheets])
  const componentsRegistry = useMemo(
    () => ({
      termsheetName: memo(DocumentNameRender),
      sentStatus: memo(SentStatusRender),
    }),
    []
  )

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Document Name',
        field: 'termsheetName',
        sortable: true,
        filter: true,
        cellRenderer: 'termsheetName',
      },
      {
        headerName: 'Overview',
        field: '',
        sortable: true,
        filter: true,
        hide: true,
      },
      {
        headerName: 'Status',
        field: 'status',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Round',
        field: 'round',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Invested Amount',
        field: 'investedAmount',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Issued Date',
        field: 'issueDate',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Valid Date',
        field: 'validity',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Sent To Hissa',
        field: 'sentStatus',
        sortable: true,
        filter: true,
        cellRenderer: 'sentStatus',
      },
      {
        headerName: 'Last Modified',
        field: 'updatedAt',
        sortable: true,
        filter: true,
      },
      {
        headerName: '',
        field: 'actions',
        pinned: 'right',
        hide: false,
        width: 60,
        maxWidth: 60,
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: {
          'padding-left': '9px',
          'padding-right': '9px',
          'padding-top': '5px',
          'line-height': '20px',
        },
        resizable: false,
        sortable: false,
        cellRenderer: DealsActionRender,
      },
    ],
    []
  )
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [data?.termsheets])
  return (
    <div
      className='card p-0 h-600px overflow-auto'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='d-flex flex-row border-0 p-9'>
        <div className='card-title align-items-end flex-column'>
          <h1 className='m-0'>My Documents</h1>
          {active + ' Active, ' + lapsed + ' Lapsed, ' + draft + ' Draft Documents'}
          <span className='text-muted'></span>
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          <CloudSetting
            tableId={TableId.dealsTable + 'web'}
            isLoading={isSaving}
            filterCondition={filterCondition}
            setFilterCondition={setFilterCondition}
          />
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          onFilterChanged={onAgGridFilterChanged}
          onColumnMoved={uploadColumn}
          onColumnEverythingChanged={uploadColumn}
          onColumnResized={uploadColumn}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={true}
          enableRangeSelection={true}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          suppressMenuHide={false}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}

export default DealsAGTable
