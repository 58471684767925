import { RateInterval, convertRate, xirr } from 'node-irr'
import {
  AllTransactionsType,
  InvestorObject,
  TransactionObject,
  Transaction_Type,
} from '../../../../generated/graphql'
import { calculateXirr } from '../../../../utils/Utils'
import { nonFDBSubTypes } from './nonFdbSubTypes'

export type InvestorParameters = {
  investmentAmount: number
  realizedValue: number
  holdingPercentage: number
  unrealizedValue: number
  irr: number
  moic: number
  fdbShares?: number
  latestRaise?: number
  companyValuation?: number
  transactionObjects?: TransactionObject[]
  investorObjects?: InvestorObject[]
}
export function calculateInvestorParameters(allTransactions: AllTransactionsType) {
  const invParams: InvestorParameters = {
    investmentAmount: 0,
    realizedValue: 0,
    holdingPercentage: 0,
    unrealizedValue: 0,
    irr: 0,
    moic: 1,
    latestRaise: 0,
    companyValuation: 0,
    fdbShares: 0,
  }

  const irrData: { date: Date; amount: number }[] = []

  allTransactions?.transactionData?.forEach((tx) => {
    if (
      (tx?.transactionType === Transaction_Type.Buy ||
        tx?.transactionType === Transaction_Type.SecondaryBuy) &&
      !nonFDBSubTypes.includes(tx.securitySubType as string)
    ) {
      invParams.investmentAmount += tx?.amount
      irrData.push({
        amount: Math.abs(tx?.amount) * -1,
        date: new Date(tx?.transactionDate),
      })
    }
    if (tx?.transactionType === Transaction_Type.Sell) {
      invParams.realizedValue += Math.abs(tx?.amount)
      irrData.push({
        amount: Math.abs(tx?.amount),
        date: new Date(tx?.transactionDate),
      })
    }
  })
  allTransactions?.investorData?.forEach((inv) => {
    invParams.holdingPercentage += inv?.fdbHoldingPercentage || 0
    invParams.unrealizedValue += inv?.currentValue || 0
    invParams.fdbShares !== undefined && (invParams.fdbShares += inv?.fdbShares || 0)
  })
  allTransactions?.indirectTransactionData?.forEach((tx) => {
    invParams.investmentAmount += tx?.drawDown || 0
    irrData.push({
      amount: Math.abs(tx?.drawDown || 1) * -1,
      date: new Date(tx?.transactionDate as string),
    })
  })

  if (irrData.length > 0) {
    irrData.push({
      date: new Date(),
      amount: invParams.unrealizedValue,
    })
    if (isNaN(convertRate(xirr(irrData).rate, RateInterval.Year))) {
      const values = irrData.map((value: any) => value.amount)
      const dates = irrData.map((date: any) => new Date(date.date))
      const irrValue = calculateXirr(values, dates)
      invParams.irr = irrValue !== 'NA' ? irrValue * 100 : 0
    } else {
      invParams.irr = convertRate(xirr(irrData).rate, RateInterval.Year) * 100
    }
  }

  invParams.latestRaise = allTransactions?.companyData.latestRaise || 1
  invParams.companyValuation = allTransactions?.companyData.valuation || 1
  invParams.moic =
    invParams.investmentAmount !== 0
      ? (invParams.realizedValue + invParams.unrealizedValue) / invParams.investmentAmount
      : 1
  return invParams
}
