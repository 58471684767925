export const TableId = {
  portfolioTable: 'portfolioTable',
  transactionTable: 'transactionTable',
  indirectInvTable: 'indirectInvTable',
  valuationReportTable: 'valuationReportTable',
  watchlistTable: 'watchlistTable',
  dealsTable: 'dealsTable',
  investmentModellingTable: 'investmentModellingTable',
  exitModellingTable: 'exitModellingTable',
  watchlistCapTable: 'watchlistCapTable',
}

export const logoImage = 'https://equion-dev.s3.us-west-2.amazonaws.com/hissa-logo.png'
export const logoDarkImage = 'https://equion-dev.s3.us-west-2.amazonaws.com/hissa-logo-dark.png'
