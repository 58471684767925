import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import { useContext, useEffect, useState } from 'react'
import { SelectOptionType } from '../../../../../utils/MultiSelect'
import { investmentModellingSecurity } from '../../../company/utils/nonFdbSubTypes'
import { selectCustomStyles } from '../../../../../utils/Select'
import {
  formatNumber,
  getCurrency,
  getSymbolOfCurrency,
} from '../../../../../utils/CurrencyFormatter'
import { GlobalContext } from '../../../store/context/globalContext'
import {
  AddTransactionRoundDetails,
  InvestmentRoundDetails,
} from '../../../company/components/InvestmentModelling/InvestmentModellingHelper'
import CreatableSelect from 'react-select/creatable'
import Toast, { ToastType } from '../../../../../utils/toast'
import { InputBox } from '../../../../../utils/NumberInputBox'
import { useCompanyNameStore } from '../../../../../store/companyNameStore'

type TransactionProps = {
  investments: AddTransactionRoundDetails[]
  handleClose: () => void
  setInvestmentData: Function
  removeInvestmentData: Function
  isEdit?: boolean
  investmentData?: AddTransactionRoundDetails
  currentRoundSize: number
  shareHolderNameList: SelectOptionType[]
  sharePrice: number
  currentPercentage: number
  roundDetails: InvestmentRoundDetails
}

export const AddRoundTransactionForm = (props: TransactionProps) => {
  const [currentRoundSize, setCurrentRoundSize] = useState<number>(
    props.roundDetails?.roundSize || 0
  )
  const [currentPercentage, setCurrentPercentage] = useState<number>(0)
  const [selectedType, setSelectedType] = useState(
    props.investmentData?.selectedType ||
      (props.investments && props.investments[0]?.selectedType) ||
      'Percentage'
  )

  const securitiesList: SelectOptionType[] = []
  investmentModellingSecurity.forEach((security: string) => {
    securitiesList.push({
      value: security,
      label: security,
    })
  })

  const companyNameStore = useCompanyNameStore()
  const companyCurrency = companyNameStore.companyCurrency
  const companyCurrencyFormat = getCurrency(companyCurrency)

  const [security, setSecurity] = useState<any>(
    props.isEdit
      ? {
          value: props.investmentData?.securityType,
          label: props.investmentData?.securityType,
        }
      : {
          value: 'Equity',
          label: 'Equity',
        }
  )

  useEffect(() => {
    let roundSize = 0
    if (props.investments && props.roundDetails) {
      roundSize = props.investments?.reduce((acc, curr) => acc + curr.investmentAmount, 0)
      if (props.isEdit) {
        roundSize = roundSize - (Number(props.investmentData?.investmentAmount) || 0)
      }
      setCurrentRoundSize(Number(props.roundDetails?.roundSize) - Number(roundSize))
      setCurrentPercentage(
        ((Number(props.roundDetails?.roundSize) - roundSize) /
          Number(props.roundDetails?.roundSize)) *
          100
      )
    }

    if (props.roundDetails) {
      setCurrentPercentage(
        ((Number(props.roundDetails.roundSize) - roundSize) /
          Number(props.roundDetails.roundSize)) *
          100
      )
    }
  }, [props.roundDetails, props.investments])

  const initialTransactionDetails: AddTransactionRoundDetails = props.isEdit
    ? {
        shareholderName: (props.investmentData && props.investmentData.shareholderName) || '',
        securityType: (props.investmentData && props.investmentData.securityType) || 'Equity',
        noOfShares: (props.investmentData && props.investmentData.noOfShares) || 0,
        pricePerShare: Number(props.sharePrice.toFixed(2)) || 0,
        investmentAmount: (props.investmentData && props.investmentData.investmentAmount) || 0,
        percentage: (props.investmentData && props.investmentData.percentage) || 0,
        nominalEquityShares:
          (props.investmentData && props.investmentData.nominalEquityShares) || 0,
        selectedType: selectedType,
      }
    : {
        shareholderName: '',
        securityType: 'Equity',
        noOfShares: 0,
        pricePerShare: Number(props.sharePrice.toFixed(2)) || 0,
        investmentAmount: 0,
        percentage: 0,
        nominalEquityShares: 0,
        selectedType: selectedType,
      }

  const transactionRoundSchema = Yup.object().shape({
    shareholderName: Yup.string().required().label('Investor Name'),
    securityType: Yup.string().required().label('Security'),
    noOfShares: Yup.number()
      .required()
      .moreThan(0, 'Should be greater than 0')
      .label('No. of Securities'),
    pricePerShare: Yup.number()
      .required()
      .moreThan(0, 'Should be greater than 0')
      .label('SharePrice'),
    investmentAmount: Yup.number().when(['selectedType'], {
      is: (selectedType: string) => selectedType === 'Amount',
      then: Yup.number()
        .required()
        .moreThan(0, 'Should be greater than 0')
        .label('Investing Amount'),
      otherwise: Yup.number().required(),
    }),
    percentage: Yup.number().when(['selectedType'], {
      is: (selectedType: string) => selectedType === 'Percentage',
      then: Yup.number()
        .required()
        .moreThan(0, 'Should be greater than 0')
        .max(100, 'Should be less than or equal to 100')
        .label('Percentage'),
      otherwise: Yup.number().required(),
    }),
    nominalEquityShares: Yup.number().min(0).label('Nominal Equity Shares'),
  })

  const formik = useFormik<AddTransactionRoundDetails>({
    initialValues: initialTransactionDetails,
    validationSchema: transactionRoundSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      if (props.isEdit) {
        const updatedValue = props.investments.filter(
          (item: AddTransactionRoundDetails) =>
            item.shareholderName !== props.investmentData?.shareholderName &&
            item.investmentAmount !== props.investmentData?.investmentAmount
        )
        if (updatedValue) {
          updatedValue.push(values)
          props.setInvestmentData(updatedValue as AddTransactionRoundDetails[])
        }
        Toast('Investment Edited Successfully', ToastType.success)
      } else {
        const updatedValue = (props.investments && [...props.investments]) || []
        updatedValue.push(values)
        props.setInvestmentData(updatedValue as AddTransactionRoundDetails[])
        Toast('Investment Added Successfully', ToastType.success)
      }
      props.handleClose()
    },
  })

  useEffect(() => {
    if (formik.values.pricePerShare > 0 && formik.values.investmentAmount > 0) {
      formik.setFieldValue(
        'noOfShares',
        Math.floor(formik.values.investmentAmount / formik.values.pricePerShare)
      )
    }
    if (formik.values.investmentAmount === 0 || formik.values.investmentAmount.toString() === '') {
      formik.setFieldValue('noOfShares', 0)
    }
    const totalRoundSize = props.roundDetails?.roundSize || 0

    if (totalRoundSize > 0 && formik.values.investmentAmount > 0 && selectedType === 'Amount') {
      const percentage = (formik.values.investmentAmount / totalRoundSize) * 100
      formik.setFieldValue('percentage', Number(percentage.toFixed(2)))
    }
    if (formik.values.investmentAmount === 0 || formik.values.investmentAmount.toString() === '') {
      formik.setFieldValue('percentage', 0)
    }
  }, [formik.values.pricePerShare, formik.values.investmentAmount])

  useEffect(() => {
    const totalRoundSize = props.roundDetails?.roundSize || 0
    const investmentAmount = (totalRoundSize * formik.values.percentage) / 100

    if (formik.values.pricePerShare > 0 && investmentAmount > 0 && selectedType === 'Percentage') {
      formik.setFieldValue('investmentAmount', investmentAmount)

      formik.setFieldValue('noOfShares', Math.floor(investmentAmount / formik.values.pricePerShare))
    }

    if (
      (investmentAmount === 0 || formik.values.percentage.toString() === '') &&
      formik.values.selectedType === 'Percentage'
    ) {
      formik.setFieldValue('noOfShares', 0)
      formik.setFieldValue('investmentAmount', 0)
    }
  }, [formik.values.pricePerShare, formik.values.percentage, props.roundDetails])

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form px-0'>
      <div className='w-100 mx-auto'>
        <div className='row gx-9'>
          <div className='fv-row mb-10 col-12 col-sm-6'>
            <label className={`form-label ${props.isEdit ? '' : 'required'}`}>Investor Name</label>

            {props.isEdit ? (
              <input
                type='text'
                className='form-control custom-input fw-bold shadow-lg h-38px'
                disabled={true}
                {...formik.getFieldProps('shareholderName')}
              />
            ) : (
              <>
                <CreatableSelect
                  className=''
                  styles={selectCustomStyles}
                  inputId='aria-company-input'
                  name='aria-company'
                  options={props.shareHolderNameList}
                  isClearable={true}
                  onChange={(e: any) => {
                    if (e && e !== null) {
                      formik.setFieldValue('shareholderName', e.label)
                    }
                  }}
                  formatCreateLabel={(inputText) => `Add "${inputText}"`}
                />
                <div className='text-danger mt-2'>
                  {formik.touched.shareholderName && formik.errors.shareholderName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.shareholderName}</div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className='fv-row mb-10 col-12 col-sm-6'>
            <label className='form-label required'>Security</label>

            <Select
              options={securitiesList}
              value={security}
              name='securityType'
              onChange={(e: any) => {
                setSecurity(e)
                formik.setFieldValue('securityType', e.value)
              }}
              styles={selectCustomStyles}
            />

            <div className='text-danger mt-2'>
              {formik.touched.securityType && formik.errors.securityType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.securityType}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='row gx-9'>
          <div className='fv-row mb-10 col-12 col-sm-6'>
            <label className='form-label'>
              {`Share Price (${getSymbolOfCurrency(companyCurrency)})`}
            </label>

            <InputBox
              isDisabled={false}
              name={'pricePerShare'}
              handleChange={(value: number) => {
                formik.setFieldValue('pricePerShare', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('pricePerShare', value)
              }}
              currency={companyCurrency}
              placeholder='Enter Share Price'
              value={formik.values.pricePerShare}
              isCurrency={true}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.pricePerShare && formik.errors.pricePerShare && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.pricePerShare}</div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 col-12 col-sm-6'>
            <label className='form-label'>No. of Securities</label>

            <InputBox
              isDisabled={false}
              name={'noOfShares'}
              handleChange={(value: number) => {
                formik.setFieldValue('noOfShares', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('noOfShares', value)
              }}
              currency={companyCurrency}
              placeholder='Enter No. of Securities'
              value={formik.values.noOfShares}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.noOfShares && formik.errors.noOfShares && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.noOfShares}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='row gx-9 mb-10'>
          <h4 className='mb-5 text-gray-600'>Select Method of Investment</h4>

          <div className='fv-row mb-4 d-flex flex-row gx-9'>
            <div className='col-6 d-flex flex-row align-items-center'>
              <input
                className='form-check-input cursor-pointer'
                type='radio'
                value='Percentage'
                checked={selectedType === 'Percentage'}
                onChange={(e) => {
                  setSelectedType(e.target.value)
                  formik.setFieldValue('investmentAmount', 0)
                  formik.setFieldValue('selectedType', 'Percentage')
                }}
                // disabled={props.investments && props.investments[0]?.selectedType === 'Amount'}
              />
              <h5 className='text-start px-4 d-flex align-items-bottom'>By Percentage of Shares</h5>
            </div>
            <div className='col-6 ps-md-5'>
              <InputBox
                isDisabled={selectedType === 'Amount'}
                name={'percentage'}
                handleChange={(value: number) => {
                  formik.setFieldValue('percentage', value)
                }}
                setFieldValue={(value: number) => {
                  formik.setFieldValue('percentage', value)
                }}
                currency={companyCurrency}
                placeholder='Enter Percentage of shares'
                value={formik.values.percentage}
                type='number'
              />
              <div className='d-flex justify-content-between'>
                {selectedType === 'Percentage' ? (
                  <div className='text-danger mt-2'>
                    {formik.touched.percentage && formik.errors.percentage && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.percentage}</div>
                      </div>
                    )}

                    {currentPercentage < formik.values.percentage && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{`Percentage should be less than ${Number(
                          currentPercentage
                        ).toLocaleString(companyCurrencyFormat)}%`}</div>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {selectedType === 'Percentage' && (
                  <div className='text-muted fs-7 mt-2'>
                    <div
                      className='fv-plugins-message-container'
                      data-toggle='tooltip'
                      data-placement='top'
                      title={Number(currentPercentage).toLocaleString(companyCurrencyFormat)}
                    >
                      {`Available Percentage : ${formatNumber({
                        value: currentPercentage,
                        format: companyCurrencyFormat,
                      })}%`}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='fv-row mb-4 d-flex flex-row gx-9'>
            <div className='col-6 d-flex flex-row align-items-center'>
              <input
                className='form-check-input cursor-pointer'
                type='radio'
                value='Amount'
                checked={selectedType === 'Amount'}
                onChange={(e) => {
                  setSelectedType(e.target.value)
                  formik.setFieldValue('percentage', 0)
                  formik.setFieldValue('selectedType', 'Amount')
                }}
                // disabled={props.investments && props.investments[0]?.selectedType === 'Percentage'}
              />
              <h5 className='text-start px-4 d-flex align-items-bottom'>By Investing Amount</h5>
            </div>
            <div className='col-6 ps-md-5'>
              <InputBox
                isDisabled={selectedType === 'Percentage'}
                name={'investmentAmount'}
                handleChange={(value: number) => {
                  formik.setFieldValue('investmentAmount', value)
                }}
                setFieldValue={(value: number) => {
                  formik.setFieldValue('investmentAmount', value)
                }}
                currency={companyCurrency}
                placeholder='Enter Investing Amount'
                value={formik.values.investmentAmount}
                isCurrency={true}
                type='number'
              />
              <div className='d-flex justify-content-between'>
                {selectedType === 'Amount' ? (
                  <div className='text-danger mt-2'>
                    {formik.touched.investmentAmount && formik.errors.investmentAmount && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.investmentAmount}</div>
                      </div>
                    )}
                    {currentRoundSize < formik.values.investmentAmount && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{`Investing Amount should be less than ${Number(
                          currentRoundSize
                        ).toLocaleString(companyCurrencyFormat)}`}</div>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {selectedType === 'Amount' && (
                  <div className='text-muted fs-7 mt-2'>
                    <div
                      className='fv-plugins-message-container'
                      data-toggle='tooltip'
                      data-placement='top'
                      title={Number(currentRoundSize).toLocaleString(companyCurrencyFormat)}
                    >
                      {`Available Amount : ${formatNumber({
                        value: currentRoundSize,
                        format: companyCurrencyFormat,
                      })}`}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='row gx-9'>
          {formik.values.securityType !== 'Equity' && (
            <div className='fv-row mb-10 col-12 col-sm-6'>
              <label className='form-label'>Nominal Equity Shares </label>

              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder='Enter Nominal Equity Shares'
                {...formik.getFieldProps('nominalEquityShares')}
              />

              <div className='text-danger mt-2'>
                {formik.touched.nominalEquityShares && formik.errors.nominalEquityShares && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.nominalEquityShares}</div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className='d-flex justify-content-between'>
          <button
            className='px-4 rounded border border-1 border-primary bg-transparent h-38px fw-normal text-primary'
            onClick={() => props.handleClose()}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
            disabled={currentRoundSize < formik.values.investmentAmount}
          >
            {props.isEdit ? 'Edit' : 'Add'}
          </button>
        </div>
      </div>
    </form>
  )
}
