import { AgGridReact } from 'ag-grid-react'
import { memo, useContext, useMemo, useRef, useState } from 'react'
import { agConfigSideBar, tableCellStyle } from '../../../../../utils/AgGridConfig'
import { ColDef } from 'ag-grid-community'
import { CompanyObject, Shareholder } from '../../../../../generated/graphql'

import { GlobalContext } from '../../../store/context/globalContext'
import { getCurrency, getSymbolOfCurrency } from '../../../../../utils/CurrencyFormatter'
import { KTSVG } from '../../../../../_metronic/helpers'
import {
  AmountInvestedRender,
  CertificateActionRender,
  CurrentValueRender,
  NumOfSecuritiesRender,
  PPSRender,
  RoundsRender,
  SecurityClassRender,
  SecurityTypeRender,
  ShareHolderRender,
  ShareNumbersRender,
  transformRangesArraytoString,
} from './ShareCertificateAGComponents'

type ShareCertificateProps = {
  data: Shareholder[]
  companyData: CompanyObject
}

export function ShareCertificateAGTable(props: ShareCertificateProps) {
  const gridApi = useRef<any>(null)
  const shareHolderData: Shareholder[] = (props.data as Shareholder[]) || []
  const companyDetails = props.companyData as CompanyObject
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  const componentsRegistry = useMemo(
    () => ({
      securityClass: memo(SecurityClassRender),
      securityType: memo(SecurityTypeRender),
      rounds: memo(RoundsRender),
      numOfSecurities: memo(NumOfSecuritiesRender),
      pps: memo(PPSRender),
      amountInvested: memo(AmountInvestedRender),
      currentValue: memo(CurrentValueRender),
      shareNumbers: memo(ShareNumbersRender),
      shareHolder: memo(ShareHolderRender),
    }),
    []
  )

  const showNoRowsOverlay = () => (
    <p className='fs-base text-muted text-center'>
      No Share Certificates found For Selected Shareholder.
    </p>
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Security Class',
        field: 'securityClass',
        cellRenderer: 'securityClass',
        cellStyle: tableCellStyle,
        pinned: 'left',
        initialWidth: 200,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Rounds',
        field: 'rounds',
        filter: 'agTextColumnFilter',
        cellRenderer: 'rounds',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipRounds',
      },
      {
        headerName: 'Shareholder',
        field: 'shareholder',
        filter: 'agTextColumnFilter',
        cellRenderer: 'shareholder',
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ['filterMenuTab'],
      },

      {
        headerName: 'Security Type',
        field: 'securityType',
        filter: 'agTextColumnFilter',
        cellRenderer: 'securityType',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },

      {
        headerName: `No. of Securities`,
        field: 'numOfSecurities',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'numOfSecurities',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipNumOfSecurities',
      },
      {
        headerName: `PPS (${getSymbolOfCurrency(
          props.companyData.companyCurrency || userCurrency
        )})`,
        field: 'pps',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'pps',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipPPS',
      },

      {
        headerName: `Amount Invested (${getSymbolOfCurrency(
          props.companyData.companyCurrency || userCurrency
        )})`,
        field: 'amountInvested',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'amountInvested',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipAmountInvested',
      },
      {
        headerName: `Current Value (${getSymbolOfCurrency(
          props.companyData.companyCurrency || userCurrency
        )})`,
        field: 'currentValue',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'currentValue',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipCurrentValue',
      },

      {
        headerName: 'Share Numbers',
        field: 'shareNumbers',
        filter: 'agTextColumnFilter',
        cellRenderer: 'shareNumbers',
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: '',
        field: 'actions',
        pinned: 'right',
        hide: false,
        width: 60,
        maxWidth: 60,
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: {
          'padding-left': '9px',
          'padding-right': '4px',
          'padding-top': '3px',
          'line-height': '20px',
        },
        resizable: false,
        sortable: false,
        cellRenderer: CertificateActionRender,
      },
    ],
    [userCurrencyFormat]
  )

  const rowData = useMemo(
    () =>
      shareHolderData.flatMap(
        (shareHolder: Shareholder) =>
          shareHolder.shareCertificates?.map((certificates: any) => ({
            certificateId: certificates?.certificateId,
            securityClass: certificates?.securityClass,
            securityType: certificates.securityType,
            // rounds: `${certificates.roundDetails.roundName} (${certificates.roundDetails.roundIdentifier})`, // Convert to string
            rounds: certificates.roundDetails,
            // tooltipRounds:
            //   Object.keys(certificates.roundDetails)?.length > 0
            //     ? certificates.roundDetails.roundIdentifier +
            //       ' - ' +
            //       certificates.roundDetails.roundName
            //     : '-',
            numOfSecurities: certificates.numberOfShares,
            pps: certificates.pricePerShare,
            amountInvested: certificates.amountInvested,
            currentValue: certificates.currentValue,
            shareNumbers: [
              transformRangesArraytoString(certificates.ranges, certificates.securityType),
            ],
            companyDetails: companyDetails,
            demat: certificates.demat,
            shareholder: shareHolder.name,
            certificateUrl: certificates.certificateUrl,
            certificateName: certificates.certificateName,
            userCurrencyFormat: getCurrency(companyDetails.companyCurrency || userCurrencyFormat),
            userCurrency: getCurrency(companyDetails.companyCurrency || userCurrencyFormat),
            tooltipNumOfSecurities: Math.abs(certificates.numberOfShares!).toLocaleString(
              getCurrency(companyDetails.companyCurrency || userCurrencyFormat)
            ),
            tooltipPPS: Math.abs(certificates.pricePerShare!).toLocaleString(
              getCurrency(companyDetails.companyCurrency || userCurrencyFormat)
            ),
            tooltipAmountInvested: Math.abs(certificates.amountInvested!).toLocaleString(
              getCurrency(companyDetails.companyCurrency || userCurrencyFormat)
            ),
            tooltipCurrentValue: Math.abs(certificates.currentValue!).toLocaleString(
              getCurrency(companyDetails.companyCurrency || userCurrencyFormat)
            ),
          })) || []
      ),
    [shareHolderData, userCurrencyFormat, companyDetails]
  )

  return (
    <div className='card p-0 h-600px'>
      <div className='d-flex flex-row border-0 p-9 px-0 pt-2'>
        <div className='card-title align-items-end flex-column'>
          {/* <h1 className='m-0'>My Transactions</h1>
          <span className='text-muted'>
            {valuationData?.length > 0 ? `${valuationData?.length} Transactions` : ''}
          </span> */}
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 px-0 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={true}
          enableRangeSelection={false}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          noRowsOverlayComponent={showNoRowsOverlay}
          suppressMenuHide={false}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
