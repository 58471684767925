import { useContext, useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { InvestmentModelingForm } from './InvestmentModelingForm'
import {
  AddTransactionRoundDetails,
  InvestmentModellingStepperType,
  InvestmentRoundDetails,
} from './InvestmentModellingHelper'
import { InvestmentModelHeader } from './InestmentModelHeader'
import { calculateSharePrice } from './utils'
import {
  CompanyDataInput,
  CompanyTypes,
  GetCompanyWiseDataQuery,
  GetInvestorRoundModellingDataQuery,
  GetInvestorRoundModellingQuery,
  IndividualInvestorCapTable,
  SaveInvestorRoundModellingDataMutation,
  SaveInvestorRoundModellingDataMutationVariables,
  Shareholder,
  useGetAllInvestorRoundModellingDataQuery,
  useGetCompanyWiseDataQuery,
  useGetInvestorRoundModellingDataQuery,
  useGetInvestorRoundModellingQuery,
  useSaveInvestorRoundModellingDataMutation,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import { useNavigate, useParams } from 'react-router-dom'
import { TransactionAGTable } from './TransactionAGTable'
import { SelectOptionType } from '../../../../../utils/MultiSelect'
import Toast, { ToastType } from '../../../../../utils/toast'
import { GlobalContext } from '../../../store/context/globalContext'
import { getCurrency } from '../../../../../utils/CurrencyFormatter'
import PrePostPieChart from './PrePostPieChart'
import { PrePostAGTable } from './PrePostAGTable'
import { SaveModelPopup } from './SaveModelPopup'
import { DeleteInvestmentModel } from './InvestmentModellingAGComponents'
import { useCompanyNameStore } from '../../../../../store/companyNameStore'

const InvestmentModellingStepper = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  const companyNameStore = useCompanyNameStore()
  const companyCurrency = companyNameStore.companyCurrency
  const companyCurrencyFormat = getCurrency(companyCurrency)

  const [isStepperCollapsed, setIsStepperCollapsed] = useState(false)
  const [totalInvestment, setTotalInvestment] = useState<number>(0)
  const [sharePrice, setSharePrice] = useState<number>(0)
  const [isGenerate, setIsGenerate] = useState<boolean>(false)
  const [isGenerateLoading, setIsGenerateLoading] = useState<boolean>(false)
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false)
  const [isDeleteModel, setIsDeleteModel] = useState<boolean>(false)
  const [getModel, setGetModel] = useState<boolean>(true)
  const [modellingStepper, setModellingStepper] = useState<InvestmentModellingStepperType>({
    modelId: 0,
    modelName: '',
    roundData: {} as InvestmentRoundDetails,
    investmentData: [] as AddTransactionRoundDetails[],
    currentStepIndex: 1,
    latestStepIndex: 1,
    leadInvestor: '-',
  })
  const [currentRoundSize, setCurrentRoundSize] = useState<number>(
    modellingStepper.roundData?.roundSize || 0
  )
  const [currentPercentage, setCurrentPercentage] = useState<number>(
    modellingStepper.roundData?.roundSize || 0
  )

  const sections = [
    { name: 'Round Details', subText: 'Enter Round Details' },
    { name: 'Investments', subText: 'Add Investments' },
    { name: 'Preview', subText: 'Preview Captable' },
  ]

  const { companyType, id, modelId } = useParams()
  const navigate = useNavigate()

  const compType = companyType as CompanyTypes
  let hissaCompanyId = ''
  let companyId = 0
  compType === CompanyTypes.Hissa ? (hissaCompanyId = id || '') : (companyId = Number(id) || 0)

  const input: CompanyDataInput = {
    type: compType,
    hissaCompanyId: hissaCompanyId,
    id: companyId,
  }

  const updateModellingData = (objKey: string, value: any) => {
    setModellingStepper((prevState) => ({
      ...prevState,
      [objKey]: value,
    }))
  }

  const mutateSaveModel = useSaveInvestorRoundModellingDataMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: SaveInvestorRoundModellingDataMutation,
        _variables: SaveInvestorRoundModellingDataMutationVariables,
        _context: unknown
      ) => {
        const compKey = {
          input: { hissaCompanyId: hissaCompanyId },
        }
        const getDataKey = {
          input: { hissaCompanyId: hissaCompanyId, id: data.saveInvestorRoundModellingDetails?.id },
        }
        setIsSaveLoading(false)
        queryClient.invalidateQueries(useGetAllInvestorRoundModellingDataQuery.getKey(compKey))
        queryClient.invalidateQueries(useGetInvestorRoundModellingDataQuery.getKey(getDataKey))
        if (modellingStepper.currentStepIndex !== 3) {
          setTimeout(() => {
            setGetModel(true)
            navigate(
              `/company/newmodelling/HISSA/${hissaCompanyId}/${data.saveInvestorRoundModellingDetails?.id}`
            )
          }, 10)
        } else {
          navigate(`/company/investmentmodelling/HISSA/${hissaCompanyId}`)
          Toast('Model Saved Successfully!', ToastType.success)
        }
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const { data } = useGetCompanyWiseDataQuery<GetCompanyWiseDataQuery, Error>(
    graphqlRequestClient,
    {
      input: input,
    },
    {
      enabled:
        (hissaCompanyId !== '' || companyId !== 0) &&
        Object.values(CompanyTypes).includes(compType),
    }
  )

  const { data: prePostData, isFetched } = useGetInvestorRoundModellingQuery<
    GetInvestorRoundModellingQuery,
    Error
  >(
    graphqlRequestClient,
    {
      input: {
        companyName: data?.getCompanyData?.companyData?.name,
        investors: modellingStepper.investmentData,
        roundDetails: modellingStepper.roundData,
        companyId: id,
      },
    },
    {
      enabled: isGenerate,
      onSuccess(data) {
        setIsGenerateLoading(false)
        setIsGenerate(false)
        if (modellingStepper.currentStepIndex === 2)
          Toast('Captable Generated Successfully', ToastType.success)
      },
      onError() {
        setIsGenerateLoading(false)
        setIsGenerate(false)
      },
    }
  )

  const { data: investmentModellingData, isFetched: isSavedDetails } =
    useGetInvestorRoundModellingDataQuery<GetInvestorRoundModellingDataQuery, Error>(
      graphqlRequestClient,
      {
        input: { hissaCompanyId: hissaCompanyId, id: modelId },
      },
      {
        enabled:
          getModel &&
          hissaCompanyId !== '' &&
          Number(modelId) !== 0 &&
          Object.values(CompanyTypes).includes(compType),

        onSuccess(data) {
          if (data?.getSavedInvestorRoundModellingDetails) {
            setGetModel(false)
            if (
              data?.getSavedInvestorRoundModellingDetails.investmentModellingData.investmentData
                ?.length > 0
            )
              setIsGenerate(true)

            updateModellingData(
              'roundData',
              data?.getSavedInvestorRoundModellingDetails.investmentModellingData
                .roundData as InvestmentRoundDetails
            )
            updateModellingData(
              'modelName',
              data?.getSavedInvestorRoundModellingDetails.name as string
            )
            updateModellingData('modelId', data?.getSavedInvestorRoundModellingDetails.id as number)
            updateModellingData(
              'investmentData',
              data?.getSavedInvestorRoundModellingDetails.investmentModellingData
                .investmentData as AddTransactionRoundDetails[]
            )
            updateModellingData(
              'leadInvestor',
              data?.getSavedInvestorRoundModellingDetails.investmentModellingData
                .leadInvestor as number
            )
            updateModellingData(
              'latestStepIndex',
              data?.getSavedInvestorRoundModellingDetails.investmentModellingData
                .latestStepIndex as number
            )
          }
        },
        onError() {
          setGetModel(false)
        },
        cacheTime: 0,
        staleTime: 0,
      }
    )

  const shareHolderNameList: SelectOptionType[] = []

  if (
    data?.getCompanyData?.companyData.shareholders &&
    data?.getCompanyData?.companyData.shareholders?.length > 0
  ) {
    data?.getCompanyData?.companyData.shareholders?.forEach((shareHolder: Shareholder | null) => {
      if (
        shareHolder &&
        !shareHolder?.name.toLocaleLowerCase().replace(' ', '').includes('esoppool')
      )
        shareHolderNameList.push({
          value: shareHolder?.companyInvestorId || '',
          label: shareHolder?.name,
        })
    })
  }

  const filteredPreholdingData =
    prePostData &&
    prePostData.getInvestorRoundModellingDetails?.filter(
      (element) => element?.preHoldingFdbPercentage && element?.preHoldingFdbPercentage !== 0
    )

  const filteredPostholdingData =
    prePostData &&
    prePostData.getInvestorRoundModellingDetails?.filter(
      (element) => element?.postHoldingFdbPercentage && element?.postHoldingFdbPercentage !== 0
    )

  let newInvestorIndex: number[] = []
  if (prePostData?.getInvestorRoundModellingDetails) {
    newInvestorIndex = prePostData.getInvestorRoundModellingDetails
      .filter((element) => element?.postHoldingFdbPercentage !== 0)
      .map((element, index) => {
        if (element?.postHoldingFdbPercentage !== 0 && element?.amountInvestedInRound! > 0)
          return index
        // if (Number(element?.preHoldingFdbShares) !== Number(element?.postHoldingFdbShares))
        //   return index
        return null
      })
      .filter((element) => element !== null) as number[]
  }

  function getButton(stepper: number) {
    const buttons = ['Next', `${prePostData ? 'Preview' : 'Generate Captable'}`, 'Save Model']
    return buttons[stepper - 1] || 'Save'
  }

  function handleNext() {
    if (modellingStepper.currentStepIndex === 2) {
      if (!prePostData) {
        setIsGenerate(true)
        setIsGenerateLoading(true)
        updateModellingData('currentStepIndex', 3)
        updateModellingData('latestStepIndex', 3)
      }
      if (isFetched || prePostData) {
        updateModellingData('currentStepIndex', 3)
        updateModellingData('latestStepIndex', 3)
      }
    } else if (modellingStepper.currentStepIndex !== 3) {
      updateModellingData('currentStepIndex', modellingStepper.currentStepIndex + 1)
    } else if (modellingStepper.currentStepIndex === 3) {
      setIsSaveLoading(true)
      mutateSaveModel.mutate({
        input: {
          hissaCompanyId: hissaCompanyId,
          investorModellingDetails: modellingStepper as unknown as JSON,
          name: modellingStepper.modelName,
          id: modellingStepper.modelId,
        },
      })
    }
  }

  function handlePrevious() {
    if (modellingStepper.currentStepIndex !== 1) {
      updateModellingData('currentStepIndex', modellingStepper.currentStepIndex - 1)
    }
  }

  useEffect(() => {
    let roundSize = 0
    const roundData =
      Object.keys(modellingStepper.roundData).length > 0 ? modellingStepper.roundData : undefined

    if (modellingStepper.investmentData) {
      roundSize = modellingStepper.investmentData?.reduce(
        (acc, curr) => acc + curr.investmentAmount,
        0
      )
      const leadInvestor =
        (modellingStepper.investmentData.length > 0 &&
          modellingStepper.investmentData?.reduce((prev, current) => {
            return prev.investmentAmount > current.investmentAmount ? prev : current
          }).shareholderName) ||
        ''

      updateModellingData('leadInvestor', leadInvestor)
      setTotalInvestment(roundSize)
      setCurrentRoundSize(Number(roundData?.roundSize) - Number(roundSize))
      setCurrentPercentage(
        ((Number(roundData?.roundSize) - roundSize) / Number(roundData?.roundSize)) * 100
      )
    }
    if (roundData && !modellingStepper.investmentData) {
      setCurrentRoundSize(roundData?.roundSize)
    }
    if (roundData === undefined) {
      setSharePrice(0)
    }
    if (roundData) {
      const latestSharePrice = calculateSharePrice(roundData!, data?.getCompanyData?.companyData!)
      setSharePrice(latestSharePrice)
      setCurrentPercentage(
        ((Number(roundData.roundSize) - roundSize) / Number(roundData.roundSize)) * 100
      )
    }
  }, [modellingStepper.investmentData, modellingStepper.roundData])

  if (!isSavedDetails && Number(modelId) !== 0) {
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  }

  return (
    <>
      {(Object.keys(modellingStepper.roundData).length > 0 || Number(modelId) === 0) && (
        <>
          <div className='col d-flex flex-row justify-content-between pb-4'>
            <button
              type='button'
              onClick={() => {
                navigate(`/company/investmentmodelling/HISSA/${hissaCompanyId}`)
              }}
              className='px-4 rounded border border-1 border-hover-primary border-secondary-txt bg-transparent h-38px fw-normal text-hover-primary'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr021.svg'
                className='svg-icon-3 text-secondary-txt'
              />{' '}
              Back
            </button>
            <button
              type='button'
              onClick={() => setIsDeleteModel(true)}
              disabled={Number(modelId) === 0}
              className={`px-4 rounded h-38px fw-normal ms-4 text-white border-0 ${
                Number(modelId) === 0 ? 'disabled bg-primary-light ' : ' bg-primary '
              }`}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen027.svg'
                className='svg-icon-3 text-white'
              />{' '}
              Discard
            </button>
            {isDeleteModel && modelId && (
              <DeleteInvestmentModel
                hissaCompanyId={hissaCompanyId}
                modelId={Number(modelId)}
                setShowModel={() => setIsDeleteModel(false)}
                showModel={isDeleteModel}
              />
            )}
          </div>
          {/* Model Header Card */}
          <div className='row mb-9'>
            <div className='col-12'>
              <InvestmentModelHeader
                roundData={modellingStepper.roundData as InvestmentRoundDetails}
                leadInvestor={modellingStepper.leadInvestor}
                sharePrice={sharePrice}
                companyCurrencyFormat={companyCurrencyFormat}
                companyCurrency={companyCurrency}
                totalInvestment={totalInvestment}
              />
            </div>
          </div>
          <div ref={stepperRef} className='row mb-9 gx-4'>
            {/* Stepper Side-Nav bar */}
            <div
              className={`col-sm-12 ${isStepperCollapsed ? 'col-md-1' : 'col-md-2'}`}
              style={{
                transition: '700ms',
              }}
            >
              <div
                className={`card d-flex justify-content-center justify-content-xl-start flex-row-auto h-600px `}
              >
                <div
                  className={`d-flex flex-row-reverse `}
                  onClick={() => {
                    setIsStepperCollapsed(!isStepperCollapsed)
                  }}
                >
                  <KTSVG
                    className={`svg-icon-3x cursor-pointer ${isStepperCollapsed ? 'me-8' : ''}`}
                    path={
                      isStepperCollapsed
                        ? `/media/icons/duotune/arrows/arr080.svg`
                        : `/media/icons/duotune/arrows/arr079.svg`
                    }
                  />
                </div>
                <div
                  className={`card-body overflow-auto rounded ${
                    !isStepperCollapsed ? 'p-6' : 'px-9'
                  }`}
                  style={{
                    transition: '700ms',
                  }}
                >
                  {sections.map((section: any, index: number) => {
                    return (
                      <div className={``}>
                        <div
                          className={`d-flex flex-row ${
                            index + 1 <= modellingStepper.latestStepIndex
                              ? 'cursor-pointer'
                              : 'disabled'
                          } pb-9`}
                          onClick={() => {
                            if (index + 1 <= modellingStepper.latestStepIndex) {
                              updateModellingData('currentStepIndex', index + 1)
                            }
                          }}
                          data-toggle={`${
                            index + 1 <= modellingStepper.latestStepIndex ? '' : 'tooltip'
                          }`}
                          title={`${
                            index + 1 <= modellingStepper.latestStepIndex
                              ? ''
                              : 'Please follow the modeling steps to unlock'
                          }`}
                        >
                          <span className=''>
                            <div
                              className={`mb-5 ${
                                modellingStepper.currentStepIndex === index + 1
                                  ? 'bg-primary text-white'
                                  : index + 1 <= modellingStepper.latestStepIndex
                                  ? 'bg-primary-light text-primary'
                                  : 'bg-gray-300 text-muted'
                              } rounded-circle h-40px w-40px fw-bold d-flex justify-content-center align-items-center`}
                            >
                              <p className='mb-0 fs-2'>{index + 1}</p>
                            </div>
                          </span>
                          {!isStepperCollapsed && (
                            <span className='ps-3 pt-1'>
                              <h3
                                className={`mb-0 ${
                                  modellingStepper.currentStepIndex === index + 1
                                    ? 'text-primary'
                                    : index + 1 <= modellingStepper.latestStepIndex
                                    ? ''
                                    : 'text-muted'
                                }`}
                              >
                                {section.name}
                              </h3>
                              <span className='text-muted fs-8 '>{section.subText}</span>
                            </span>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

            <div
              className={`col-sm-12 ${isStepperCollapsed ? 'col-md-11' : 'col-md-10'}`}
              style={{
                transition: '700ms',
              }}
            >
              {/* Pre-Holding & Post-Holding pie chart */}
              {modellingStepper.currentStepIndex === 3 && (
                <div className='ms-md-5 my-5 p-0 pb-md-4 mt-md-0'>
                  {filteredPreholdingData &&
                    filteredPreholdingData.length > 0 &&
                    filteredPostholdingData &&
                    filteredPostholdingData.length > 0 && (
                      <div className='row gx-9'>
                        <div className='col-12 col-md-6 mb-9 mb-md-0'>
                          <PrePostPieChart
                            labels={filteredPreholdingData.map(
                              (element) => element?.shareholderName as string
                            )}
                            values={filteredPreholdingData.map(
                              (element) => Number(element?.preHoldingFdbPercentage) * 100
                            )}
                            header={'Pre Holding %'}
                          />
                        </div>
                        <div className='col-12 col-md-6 mb-9 mb-md-0'>
                          <PrePostPieChart
                            labels={filteredPostholdingData.map(
                              (element) => element?.shareholderName as string
                            )}
                            values={filteredPostholdingData.map(
                              (element) => Number(element?.postHoldingFdbPercentage) * 100
                            )}
                            header={'Post Holding %'}
                            grayedOutIndex={newInvestorIndex}
                          />
                        </div>
                      </div>
                    )}
                </div>
              )}

              <div
                className={`card h-600px ms-md-5 my-5 p-0 mt-md-0 overflow-auto`}
                style={{
                  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                  border: '1',
                  borderColor: '#F1F1F2',
                }}
              >
                {/* Step 1: Investment Modeling Form */}
                {modellingStepper.currentStepIndex === 1 && (
                  <InvestmentModelingForm
                    modelName={modellingStepper.modelName}
                    setSetRoundData={(value: InvestmentRoundDetails) => {
                      updateModellingData('roundData', value)
                      updateModellingData('currentStepIndex', 2)
                      updateModellingData('latestStepIndex', 2)
                      updateModellingData('modelName', value?.modelName)
                      // updateModellingData('investmentData', [] as AddTransactionRoundDetails[]) // remove this if you don't want to remove added investment when round details are updated
                    }}
                    resetInvestmentData={(value: InvestmentRoundDetails) => {
                      updateModellingData('investmentData', [] as AddTransactionRoundDetails[])
                      updateModellingData('currentStepIndex', 2)
                      updateModellingData('latestStepIndex', 2)
                      const updatedRound = modellingStepper
                      updatedRound.investmentData = []
                      updatedRound.roundData = value
                      if (modellingStepper.latestStepIndex <= 2) {
                        updatedRound.latestStepIndex = 2
                        updatedRound.currentStepIndex = 2
                      }
                      mutateSaveModel.mutate({
                        input: {
                          hissaCompanyId: hissaCompanyId,
                          investorModellingDetails: updatedRound as unknown as JSON,
                          name: value.modelName,
                          id: modellingStepper.modelId,
                        },
                      })
                    }}
                    saveModel={(value: InvestmentRoundDetails) => {
                      const updatedRound = modellingStepper
                      updatedRound.roundData = value
                      if (modellingStepper.latestStepIndex === 1) {
                        updatedRound.latestStepIndex = 2
                      }
                      mutateSaveModel.mutate({
                        input: {
                          hissaCompanyId: hissaCompanyId,
                          investorModellingDetails: updatedRound as unknown as JSON,
                          name: value.modelName,
                          id: modellingStepper.modelId,
                        },
                      })
                    }}
                    handleNextStep={() => {
                      updateModellingData('currentStepIndex', 2)
                    }}
                    roundData={modellingStepper.roundData as InvestmentRoundDetails}
                  />
                )}

                {/* Step 2: Transaction Table */}
                {modellingStepper.currentStepIndex === 2 && (
                  <>
                    {sharePrice && sharePrice > 0 && (
                      <TransactionAGTable
                        roundDetails={modellingStepper.roundData}
                        companyData={data?.getCompanyData?.companyData!}
                        data={modellingStepper.investmentData!}
                        setInvestmentData={(value: AddTransactionRoundDetails[]) => {
                          updateModellingData('investmentData', value)
                        }}
                        removeInvestmentData={(value: AddTransactionRoundDetails) => {
                          const updatedValue = modellingStepper.investmentData
                            ? modellingStepper.investmentData.filter(
                                (item: AddTransactionRoundDetails) =>
                                  item.shareholderName !== value.shareholderName ||
                                  item.investmentAmount !== value.investmentAmount ||
                                  item.securityType !== value.securityType ||
                                  item.nominalEquityShares !== value.nominalEquityShares
                              )
                            : modellingStepper.investmentData
                          updateModellingData('investmentData', updatedValue)
                        }}
                        currentRoundSize={currentRoundSize}
                        shareHolderNameList={shareHolderNameList}
                        sharePrice={sharePrice}
                        currentPercentage={currentPercentage}
                      />
                    )}
                  </>
                )}

                {/* Step 3: Pre/Post CapTable */}
                {modellingStepper.currentStepIndex === 3 && (
                  <div>
                    {!prePostData && (
                      <div className='w-100 my-20 d-flex h-525px justify-content-center align-items-center'>
                        <span className='spinner-grow'></span>
                      </div>
                    )}
                    {prePostData &&
                      prePostData.getInvestorRoundModellingDetails &&
                      data?.getCompanyData && (
                        <div className='card p-0 h-525px overflow-auto'>
                          <PrePostAGTable
                            data={
                              prePostData.getInvestorRoundModellingDetails as IndividualInvestorCapTable[]
                            }
                          />
                        </div>
                      )}
                  </div>
                )}
                {/* Stepper Buttons */}
                <div
                  className={`d-flex mb-0 px-9 ${
                    modellingStepper.currentStepIndex !== 1
                      ? 'justify-content-between py-5'
                      : 'justify-content-end'
                  } `}
                >
                  {modellingStepper.currentStepIndex !== 1 ? (
                    <button
                      onClick={() => {
                        handlePrevious()
                      }}
                      type='button'
                      className='px-4 rounded border border-1 border-primary bg-transparent h-38px fw-normal text-primary'
                    >
                      Back
                    </button>
                  ) : (
                    <div></div>
                  )}

                  {modellingStepper.currentStepIndex !== 1 && (
                    <button
                      type='button'
                      onClick={() => {
                        handleNext()
                      }}
                      className={`px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white ${
                        modellingStepper.currentStepIndex === 2 && totalInvestment === 0
                          ? 'disabled bg-primary-light'
                          : ''
                      }`}
                      disabled={
                        (modellingStepper.currentStepIndex === 2 &&
                          modellingStepper.roundData &&
                          Number(modellingStepper.roundData?.roundSize) < totalInvestment) ||
                        totalInvestment === 0 ||
                        (isSaveLoading && modellingStepper.currentStepIndex === 3) ||
                        (!prePostData && modellingStepper.currentStepIndex === 3)
                      }
                    >
                      <span className='indicator-label'>
                        {getButton(modellingStepper.currentStepIndex)}
                      </span>
                      {isGenerateLoading && modellingStepper.currentStepIndex === 2 && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default InvestmentModellingStepper
